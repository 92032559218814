
/* ==========================================================================
   Mobile Navigation
   ========================================================================== */

/* Mobile Toggle / Close
   ========================================================================== */
.mobile-menu-toggle {
    transition: opacity $trans-time--m $trans-func--easing;
    position: absolute;
    right: 0;
    top: 0;
    padding: 1rem 0 1rem 2rem;
    cursor: pointer;
    z-index: 103;
    opacity: 1;

    .svg-ico {
        width: 2rem;
        height: 2rem;
    }
}

/* Mobile nav container and wrapper
   ========================================================================== */
.mobile-menu-container {
    transform: translate3d(100%, 0, 0);
    box-shadow: inset 2rem 0 2rem -1rem rgba(0, 0, 0, 0.05);
    position: fixed;
    display: block;
    z-index: map-get($z-index, mobile-menu);
    background-color: #fff;
    text-align: left;
    width: 24rem;
    height: 100%;
    right: 0;
    top: 0;
    transition: transform $trans-time--overlay $trans-func--overlay;
}

.mobile-menu-wrapper {
    box-sizing: border-box;
    padding: 1rem 2rem 2rem 2rem;
    width: 100%;
}

/* Mobile smenu header
   ========================================================================== */
.mobile-menu-header {
    box-sizing: border-box;
    display: block;
    position: relative;
    height: 4rem;
    text-align: center;

    .logo-link.logo-link--mobile {
        display: inline-block;
        margin-top: 1.3rem;
        text-decoration: none;
    }

    img {
        width: 12rem;
        vertical-align: middle;
    }

    .btn--close-menu {
        position: absolute;
        top: 50%;
        right: 2rem;
        width: 2rem;
        height: 2rem;
        margin-top: -1rem;

        .svg-ico {
            width: 100%;
            height: 100%;
        }
    }
}

/* Mobile Menu Scroll
   ========================================================================== */
.mobile-menu-scroll {
    width: 100%;
    height: calc(100% - 4rem);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
}

/* Mobile search form
   ========================================================================== */
.mobile-search-form {
    margin-bottom: 2rem;

    form {
        position: relative;

        input {
            width: 100%;
            border: none;
        }

        .btn-search-submit {
            position: absolute;
            top: 1rem;
            right: 1rem;

            .svg-ico {
                width: 1rem;
                height: 1rem;
            }
        }
    }
}

/* Mobile navigation
   ========================================================================== */
.nav--mobile {
    li {
        display: block;
        position: relative;

        &.has-subnav {
            overflow: hidden;
        }
    }

    &.nav--mobile-main {
        a {
            @include typo-zurich;
            @include typo-zurich--1;
            font-size: 1.3rem;
            line-height: 2.5em;
            height: 2.5em;
            display: block;
        }

        > ul {
            display: flex;
            flex-direction: column;

            // Bring items in proper order
            > li {
                &:nth-child(1) { order: 1; }
                &:nth-child(2) { order: 2; }
                &:nth-child(3) { order: 5; }
                &:nth-child(4) { order: 3; }
                &:nth-child(5) { order: 4; }
                &:nth-child(6) { order: 6; }
            }
        }
    }

    &.nav--mobile-secondary,
    &.nav--mobile-tertiary {
        margin-top: 2rem;

        a {
            @include typo-zurich;
            @include typo-zurich--1;
            line-height: 2.2em;
            height: 2.2em;
            text-transform: none;
        }

        .underlined {
            border-bottom: solid 1px #000;
        }
    }

    &.nav--mobile-tertiary {
        margin-bottom: 1rem;

        &[data-basketcount="0"] {
            display: none;
        }
    }

    .ico-nav-mobile {
        display: inline-block;
        margin-right: 0.5rem;
        padding-bottom: 0.2rem;
        vertical-align: middle;
    }

    .svg-ico {
        display: inline-block;
        width: 1.1rem;
        height: 1.1rem;
    }

    .mobile-subnav-indicator {
        position: absolute;
        display: inline-block;
        top: 1rem;
        right: 0;
        width: 1rem;
        height: 1rem;
        pointer-events: none;

        .svg-ico {
            width: 100%;
            height: 100%;
            transition: transform $trans-time--s $trans-func--easing;
            transform: scaleY(1);
        }
    }

    // Prefilter groups
    .prefilter-groups {
        transition: min-height $trans-time--m $trans-func--easing;
        width: 9rem;
        min-height: 10rem;

        a {
            transition: border $trans-time--m $trans-func--easing;
            @include typo-zurich;
            @include typo-zurich--0;
            display: inline;
            line-height: 2.2em;
            height: 2.2em;
            border-bottom: solid 1px transparent;

            &.is-active {
                border-color: #000;
            }
        }
    }

    .prefilter-group-collection {
        transition: opacity $trans-time--m $trans-func--easing;
        position: absolute;
        width: 10rem;
        top: 3.2rem;
        left: 10rem;
        background-color: #fff;
        pointer-events: none;
        opacity: 0;

        a {
            @include typo-zurich;
            @include typo-zurich--0;
            display: inline;
            line-height: 2.2em;
            height: 2.2em;
            text-transform: none;
        }

        &[aria-hidden="false"] {
            opacity: 1;
            pointer-events: all;
        }

        li {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: keep-all;

            &.is-promotion {
                a {
                    color: $text-color--promotion;
                }
            }
        }
    }

    // Collection groups
    .collection-groups-collection {
        a {
            @include typo-zurich;
            @include typo-zurich--0;
            display: inline;
            line-height: 2.2em;
            height: 2.2em;
        }
    }

    // Subnav container
    .nav--sub-nav-mobile {
        max-height: 0;
        opacity: 0;
        padding-bottom: 0;
        overflow: hidden;
        transition: max-height $trans-time--m $trans-func--easing,
                    opacity $trans-time--m $trans-func--easing,
                    padding $trans-time--m $trans-func--easing;

        a {
            @include typo-zurich;
            @include typo-zurich--0;
            display: inline;
            line-height: 2.2em;
            height: 2.2em;
            text-transform: none;
        }
    }

    // Subnav is opened
    .has-subnav[aria-expanded="true"] {
        .nav--sub-nav-mobile {
            max-height: 48rem;
            opacity: 1;
            padding-bottom: $padding--xs;
        }

        // Toggle icons
        .mobile-subnav-indicator {
            .svg-ico {
                transform: scaleY(-1);
            }
        }
    }
}

@include breakpoint(mobile) {
    .has-open-nav {
        overflow: hidden;

        .mobile-menu-container {
            transform: translate3d(0, 0, 0);
        }

        .header.header--main,
        .footer.footer--main,
        .site-container,
        .filter-site-content.filter-site-content--sticky {
            transform: translate3d(-24rem, 0, 0);
        }

        .mobile-menu-toggle {
            opacity: 0;
            pointer-events: none;
        }
    }
}

// Hide it for desktop
@include breakpoint(desktop) {
    .mobile-menu-container {
        display: none;
    }
}
