.page--download {
    .download-container {
        margin-top: $margin--m;
        margin-bottom: $margin--l;
    }

    .download-category-list {
        @include pseudo-list;
        margin-bottom: $margin--l;
    }

    .download-category {
        display: block;
        width: 100%;
        text-align: left;
        margin-left: $padding--large * (-1);
        margin-right: $padding--large * (-1);

        h2 {
            @include typo-zurich-bd;
            @include typo-zurich--3;
            margin-bottom: $margin--xs;
            padding: 0 $padding--large;
        }

        &:not(:last-child) {
            margin-bottom: $margin--m;
        }
    }

    // Download group list
    .download-group-list {
        @include pseudo-list;
        display: flex;
        flex-wrap: wrap;

        .download-group {
            float: left;
            position: relative;
            width: 1 * $module-width;
            text-align: left;
        }

        .download-group-image,
        .download-group-info {
            position: relative;
            padding: $padding--large;
        }

        .download-group-headline {
            @include typo-letter;
            @include typo-letter--1;
            margin-bottom: $margin--xxs;
        }

        .download-group-description {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            margin-bottom: 0;
        }

        .download-group-image {
            width: $module-width;
            height: $module-width;
        }
        /*
        .download-group__video-count {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            pointer-events: none;

            span {
                @include typo-zurich-bd;
                @include typo-zurich--3;
                text-transform: none;
            }
        }
        */
    }

    // Download group detail
    .download-group-detail {
        display: block;
        text-align: left;

        h2 {
            @include typo-zurich-bd;
            @include typo-zurich--3;
            margin-bottom: $margin--xs;
        }
    }

    .download-group-detail-headline {
        position: relative;
        margin-bottom: $margin--s;
    }

    // Video player
    .download-video-player {
        display: block;
        position: relative;
        margin-bottom: $margin--m;

        .download-video-player-holder {
            position: relative;
            width: 100%;
            padding-bottom: 57%;
            opacity: 1;
            transition: opacity $trans-time--m $trans-func--default;
            overflow: hidden;

            > * {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        .download-item-info {
            text-align: left;
            margin-top: $margin--s;
        }

        .download-video-player-loader {
            position: absolute;
            top: calc(50% - 6rem);
            left: 50%;
            width: 32px;
            height: 32px;
            margin: -16px 0 0 -16px;
            opacity: 1;
            pointer-events: none;
            background: transparent url('../images/loader/myk-loader-default.gif') 50% 50% no-repeat;
            background-size: 32px 32px;
            transition: opacity $trans-time--m $trans-func--default;
        }

        &[aria-busy="false"] {
            .download-video-player-loader {
                opacity: 0;
            }
        }

        &[aria-busy="true"] {
            .download-video-player-holder {
                opacity: 0;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    // Download items
    .download-item-list {
        @include pseudo-list;
        display: flex;
        flex-wrap: wrap;
        margin-left: $padding--large * (-1);
        margin-right: $padding--large * (-1);
        margin-bottom: $margin--l;

        &.download-item-list--small {
            margin-bottom: $margin--s;
        }
    }

    .download-item {
        float: left;
        position: relative;
        width: $module-width;
        text-align: left;
        margin-bottom: $margin--s;

        .download-item-info {
            padding: $padding--large;
        }
    }

    .download-item-headline {
        margin-bottom: $margin--xxs;
    }

    .download-item-image-box {
        box-sizing: border-box;
        padding: $padding--large;
        overflow: hidden;

        width: $module-width;
        height: $module-height;

        &.download-item-image-box--small-quad {
            height: $module-width;
        }
    }

    .download-item-info {
        p {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
        }
    }

    .download-item-file-list {
        @include pseudo-list;
        margin: $margin--xs 0 0 0;
        padding: 0;
        list-style-type: none;
        display: block;

        li {
            display: block;

            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
        }
    }

    .download-item-image {
        position: relative;

        &.download-item-image--ratio {
            img {
                transform: translate3d(-50%, -50%, 0);
                position: absolute;
                top: 50%;
                left: 50%;
                max-width: $module-width - 4rem;
                max-height: $module-width - 4rem;
                width: auto;
                height: auto;
            }

            .download-item-image-inner {
                position: relative;
                background-color: $back-color--light-1;
                text-align: center;
                width: 100%;
                height: 100%;
            }
        }
    }

    // Language selection
    .download-group-language-selection {
        @include breakpoint(mobile) {
            text-align: right;
            display: block;
            margin-bottom: 1rem;
        }

        @include breakpoint(one) {
            text-align: center;
        }

        @include breakpoint(desktop) {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
        }

        ul {
            @include pseudo-list;
            display: inline-block;
        }

        a {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: uppercase;
            display: inline-block;
            float: left;
        }

        li {
            display: inline-block;
            float: left;
            margin-left: 0.25rem;

            a:before {
                @include typo-zurich;
                letter-spacing: 0;
                font-size: 1em;
                content: '|';
                display: inline-block;
                float: left;
                margin-right: 0.25rem;
                line-height: 1.4;
            }

            &:first-child a:before {
                content: '';
                margin: 0;
            }

            &.is-selected a {
                @include typo-zurich-bd;
            }
        }
    }

    // Pseudo play button
    .download-play-button {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -3rem 0 0 -3rem;
        width: 6rem;
        height: 6rem;
        z-index: 1;
        opacity: 0.6;
        pointer-events: none;

        .svg-ico {
            width: 100%;
            height: 100%;
            fill: #fff;
        }

        @include breakpoint(one) {
            width: 4rem;
            height: 4rem;
            margin: -2rem 0 0 -2rem;
        }

        &.download-play-button--small {
            width: 4rem;
            height: 4rem;
            margin: -2rem 0 0 -2rem;
        }
    }
}
