/*! Flickity v2.0.10
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100% !important;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

/* ---- page dots ---- */

.flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
}

.flickity-rtl .flickity-page-dots {
    direction: rtl;
}

.flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
    opacity: 1;
}

// Flickity slide
.flickity__slide {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
}

// Arrows
.flickity-prev-next-button {
    position: absolute;
    top: 0;
    background: none;
    width: auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    border-radius: 0;
    transition: opacity $trans-time--m $trans-func--easing;

    .flickity__arrow-icon {
        display: inline-block;
        margin: 0;
        width: 3rem;
        height: 3rem;
        background-size: 3rem 3rem;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: 50% 50%;

        @include breakpoint(one) {
            width: 2rem;
            height: 2rem;
            background-size: 2rem 2rem;
        }
    }

    &.previous {
        left: 0;
        margin-left: 2rem;
        padding-right: 4rem;

        @include breakpoint(two) {
            margin-left: 1rem;
            padding-right: 3rem;
        }

        @include breakpoint(one) {
            margin-left: 0.5rem;
            padding-right: 2rem;
        }
    }

    &.next {
        right: 0;
        margin-right: 2rem;
        padding-left: 4rem;

        @include breakpoint(two) {
            margin-right: 1rem;
            padding-left: 3rem;
        }

        @include breakpoint(one) {
            margin-right: 0.5rem;
            padding-left: 2rem;
        }
    }
}

.flickity-page-dots {
    bottom: 2rem;
    transition: opacity $trans-time--m $trans-func--easing;

    .dot {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        background: none;
        margin: 0 0 -10px 0;
        padding: 0;
        border-radius: 0;
        opacity: 1;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            margin: -4px 0 0 -4px;
            background: #bbbbbb;
            opacity: 1;
            border-radius: 50%;
            transition: opacity $trans-time--m $trans-func--easing,
            background $trans-time--m $trans-func--easing;
        }

        &.is-selected:before {
            background: #000000;
        }
    }

    @include breakpoint(one) {
        bottom: 1rem;
    }
}
