.team-administration {
    text-align: left;
    margin-top: $margin--s;

    .team-administration-headline {
        margin-bottom: $margin--s;
    }

    .team-member-list {
        margin-bottom: $margin--m;
    }

    .team-member {
        display: block;

        &:not(:last-child) {
            margin-bottom: $margin--xs;
        }

        h4 {
            @include typo-zurich-bd;
            @include typo-zurich--1;
            margin-bottom: $margin--xxs;
        }

        h5 {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            margin: 0;
        }

        .option-group-item[aria-hidden="true"] {
            display: none;
        }

        &[aria-hidden] {
            max-height: 50rem;
            opacity: 1;
            transition: max-height $trans-time--m $trans-func--easing,
                        opacity $trans-time--m $trans-func--easing,
                        margin $trans-time--m $trans-func--easing;

            @include breakpoint(one) {
                max-height: 80rem;
            }
        }

        &[aria-hidden="true"] {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            margin-bottom: 0;
        }
    }

    .team-member-head-inner,
    .team-member-body-column {
        width: calc(#{$module-width} * 2.5 - #{$padding--large} * 2);
    }

    .team-member-body-column {
        display: flex;
        flex-direction: column;
    }

    .team-member-body {
        display: flex;
        justify-content: space-between;
    }

    .form-row--team-member-remove {
        flex: 1 1 auto;
        display: flex;
        align-items: flex-end;
        padding-bottom: $padding--xs;
    }

    @include breakpoint(four) {
        .team-member-head-inner,
        .team-member-body-column {
            width: calc(#{$module-width} * 2 - #{$padding--large} * 2);
        }
    }

    @include breakpoint(three) {
        .team-member-head-inner,
        .team-member-body-column {
            width: calc(#{$module-width} * 1.5 - #{$padding--large} * 2);
        }
    }

    @include breakpoint(two) {
        .team-member-head-inner,
        .team-member-body-column {
            width: calc(#{$module-width} * 1 - #{$padding--large});
        }
    }

    @include breakpoint(one) {
        .team-member-body {
            flex-wrap: wrap;
        }

        .team-member-head-inner,
        .team-member-body-column {
            width: $module-width;
        }

        .team-member-body-column:first-child {
            margin-bottom: $margin--s;
        }

        .form-row--team-member-remove {
            margin-top: $margin--s;
        }
    }
}
