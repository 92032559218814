/// Breakpoint Definitions
/// @group Breakpoints
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

$bp-1--start: 1px;
$bp-1--end:   479px;

$bp-2--start: 480px;
$bp-2--end:   759px;

$bp-3--start: 760px;
$bp-3--end:   1179px;

$bp-4--start: 1180px;
$bp-4--end:   1719px;

$bp-5--start: 1720px;
$bp-5--end:   9999px;

/// Mixin for breakpoints
///
/// @group humans-machines
/// @content
/// @param {string} $name - The name of the breakpoint
@mixin breakpoint($point) {
    @if $point == five {
        @media (min-width: $bp-5--start) and (max-width: $bp-5--end) { @content; }
    }
    @else if $point == four {
        @media (min-width: $bp-4--start) and (max-width: $bp-4--end) { @content; }
    }
    @else if $point == three {
        @media (min-width: $bp-3--start) and (max-width: $bp-3--end)  { @content; }
    }
    @else if $point == two {
        @media (min-width: $bp-2--start) and (max-width: $bp-2--end)  { @content; }
    }
    @else if $point == one {
        @media (min-width: $bp-1--start) and (max-width: $bp-1--end)  { @content; }
    }
    @else if $point == fourfive {
        @media (min-width: $bp-4--start) and (max-width: $bp-5--end)  { @content; }
    }
    @else if $point == threefive {
        @media (min-width: $bp-3--start) and (max-width: $bp-5--end)  { @content; }
    }
    @else if $point == threefour {
        @media (min-width: $bp-3--start) and (max-width: $bp-4--end)  { @content; }
    }
    @else if $point == twofive {
        @media (min-width: $bp-2--start) and (max-width: $bp-5--end)  { @content; }
    }
    @else if $point == twofour {
        @media (min-width: $bp-2--start) and (max-width: $bp-4--end)  { @content; }
    }
    @else if $point == onetwo {
        @media (min-width: $bp-1--start) and (max-width: $bp-2--end)  { @content; }
    }
    @else if $point == onethree {
        @media (min-width: $bp-1--start) and (max-width: $bp-3--end)  { @content; }
    }
    @else if $point == desktop { // same as threefive
        @media (min-width: $bp-3--start) and (max-width: $bp-5--end)  { @content; }
    }
    @else if $point == mobile { // same as onetwo
        @media (min-width: $bp-1--start) and (max-width: $bp-2--end)  { @content; }
    }
    @else if $point == oneHeight {
        @media screen and (orientation: landscape) and (max-height: $bp-1--end) { @content; }
    }
    @else if $point == twoHeight {
        @media screen and (orientation: landscape) and (min-height: $bp-2--start) and (max-height: $bp-2--end) { @content; }
    }
    @else if $point == height720Landscape {
        @media screen and (orientation: landscape) and (max-height: 720px) { @content; }
    }
    @else if $point == height940Landscape {
        @media screen and (orientation: landscape) and (min-height: 721px) and (max-height: 940px) { @content; }
    }
}

/// Alias for the breakpoint mixin
///
/// @group humans-machines
/// @content
/// @param {string} $name - The name of the breakpoint
@mixin respond-to($name) {
    @include breakpoint($name) {
        @content;
    }
}
