// Hide clear in internet explorer
input::-ms-clear {
    display: none;
}

// Hide expand error for select fields
select::-ms-expand {
    display: none;
}

figure {
    margin: 0;
}

// revert blockquote styling
blockquote {
    margin: 0;
}

// revert small styling
small {
    font-size: inherit;
}
