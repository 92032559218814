// Productdetail error
// ------------------
.product-gone-container {
    text-align: left;
    margin-top: 4rem;


    h2 {
        @include typo-zurich-bd;
        @include typo-zurich--3;

        @include breakpoint(two) {
            @include typo-zurich--2;
        }

        @include breakpoint(one) {
            @include typo-zurich--1;
        }
    }

    .product-listing {
        margin-bottom: 6rem;
    }

    .product-gone-target {
        margin-bottom: 4rem;

        @include breakpoint(one) {
            margin-bottom: 2rem;
        }

        .respImage {
            opacity: 0.55;
        }
    }

    .product-gone-related {
        margin-bottom: 16rem;
    }

    .product {
        @include breakpoint(one) {
            //margin-top: -2rem;
        }
    }

    &.product-gone-container-search {
        margin-top: 4rem;

        @include breakpoint(desktop) {
            margin-top: 8rem;
        }
    }

    .gone-redirect-countdown-hint {
        transition: opacity $trans-time--m $trans-func--default;
        opacity: 1;

        &.is-hidden {
            opacity: 0;
        }
    }


    @include breakpoint(desktop) {
        margin-top: 8rem;
    }
}
