/* ==========================================================================
   header
   ========================================================================== */

.header.header--main {
    transform: translate3d(0, 0, 0);
    height: $header-height--mobile;
    position: fixed;
    top: 0;
    left: 0;
    z-index: map-get($z-index, header);
    width: 100%;
    background-color: white;
    transition: margin $trans-time--modal $trans-func--modal,
                opacity $trans-time--modal $trans-func--modal,
                transform $trans-time--overlay $trans-func--overlay;

    @include breakpoint(desktop) {
        position: relative;
        left: auto;
        top: auto;
        height: $header-height--desktop;
    }

    // Hide the main nav on mobile
    @include breakpoint(mobile) {
        .header-nav-container {
            display: none;
        }

        .has-visible-map & {
            transform: translate3d(0, $header-height--mobile * (-1), 0);
            opacity: 0;
        }
    }

    // Mobile nav
    .nav--header-mobile {
        transition: opacity $trans-time--m $trans-func--easing;
        position: absolute;
        right: 0;
        top: 0;
        padding: 1rem 0 1rem 2rem;
        cursor: pointer;
        z-index: 1;
        opacity: 1;

        ul {
            @include pseudo-list;
            display: inline-block;
        }

        li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: $margin--xs;
            }
        }

        .svg-ico {
            width: 2rem;
            height: 2rem;
        }

        @include breakpoint(desktop) {
            display: none;
        }

        .has-open-nav & {
            opacity: 0;
        }
    }
}

.header .header-container {
    position: relative;
    background: #fff;
    height: 100%;
}

.mykita-logo {
    position: absolute;
    z-index: 10;
    text-align: left;

    @include breakpoint(mobile) {
        width: 16rem;
        position: absolute;
        display: inline-block;
        top: 0.5rem;
        left: 0;

        a {
            display: block;
            padding: 1rem 1rem 0.5rem 0;
        }
    }

    @include breakpoint(desktop) {
        display: block;
        width: 22rem;
        top: 1.5rem;
        left: 0;

        a {
            display: block;
            padding: 1rem 1rem 1rem 0;
        }
    }

    img {
        width: 100%;
    }
}

// Checkout header
.header.header--checkout,
.header.header--signup {
    .header-back-to-shop {
        position: absolute;
        display: inline-block;
        right: 0;
        top: 3rem;
    }

    @include breakpoint(desktop) {
        position: fixed;
        height: 8rem;
        top: 0;
    }

    @include breakpoint(mobile) {
        .header-back-to-shop {
            top: 1.5rem;
        }
    }
}

// Header navigation
.header-nav-container {
    position: relative;
    width: 100%;
    height: 100%;

    // Display nav as flex
    .nav--main,
    .nav--functions {
        & > ul {
            display: flex;
            align-items: flex-end;
        }
    }

    // Main navigation
    .nav--main {
        box-sizing: border-box;
        position: absolute;
        left: 0;
        bottom: 1rem;
        padding: $padding--default 0;
        width: 100%;

        li {
            margin-right: 1rem;
        }

        // Bring items in proper order
        > ul > li {
            &:nth-child(1) { order: 1; }
            &:nth-child(2) { order: 2; }
            &:nth-child(3) { order: 5; }
            &:nth-child(4) { order: 3; }
            &:nth-child(5) { order: 4; }
            &:nth-child(6) { order: 6; }
        }

        .nav-item-wrapper {
            display: inline-block;
            position: relative;
        }

        a {
            @include typo-zurich;
            @include typo-zurich--1-1;
            line-height: 1;
            transition: color $trans-time--m $trans-func--default;

            &.is-active {
                @include typo-zurich-bd;
            }
        }

        .main-link {
            position: absolute;
            top: 0;
            left: 0;
        }

        .link-bold-spacer {
            @include typo-zurich-bd;
            @include typo-zurich--1-1;
            line-height: 1;
            color: #fff;
            visibility: hidden;
        }

        .main-link,
        .link-bold-spacer {
            white-space: nowrap;
            word-break: keep-all;
        }
    }

    // Hover navs
    .nav--sub-nav {
        position: absolute;
        padding: 0;
        left: 0;
        top: 1.8rem;
        width: 100%;
        background-color: rgb(255, 255, 255);
        z-index: 10;

        ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            li {
                display: block;
                text-align: left;
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 0.25rem;
                }
            }

            a {
                @include typo-zurich;
                @include typo-zurich--1;
                text-decoration: none;
                text-transform: none;

                &:hover {
                    @include typo-zurich-bd;
                    text-transform: none;
                }
            }

            .link-bold-spacer {
                @include typo-zurich-bd;
                @include typo-zurich--1-1;
                line-height: 1;
                color: #fff;
                visibility: hidden;
                white-space: nowrap;
                word-break: keep-all;
            }

            .link-bold-spacer + a {
                position: absolute;
                top: 0;
                left: 0;
            }

            // Promotion
            .is-promotion {
                & > a,
                & > span {
                    color: $text-color--promotion;
                }
            }
        }
    }

    // Subnav Grouping
    .nav--group {
        text-align: left;
        margin: 1rem 0;
        min-width: 12rem;

        h3 {
            @include typo-zurich;
            @include typo-zurich--1-1;
            margin-bottom: 1rem;
        }

        &:not(:last-child) {
            margin-right: $margin--m;
        }
    }

    .nav--sub-nav {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: opacity $trans-time--modal $trans-func--modal,
                    max-height $trans-time--modal $trans-func--modal;

        .subnav-inner {
            display: flex;
            padding: 1.2rem 0 2rem 0;
            flex-wrap: wrap;
        }
    }

    // Nav functions
    .nav--functions {
        padding: $padding--default 0;

        li:not(.nav-search) {
            padding-bottom: $padding--xxxs;
        }
    }

    .nav--functions.nav--functions-main {
        position: absolute;
        top: 2.4rem;
        right: 0;

        li {
            margin-left: 1.5rem;
        }

        a,
        span {
            @include typo-zurich;
            @include typo-zurich--0;
            text-transform: none;
            line-height: 1;

            &.is-active {
                @include typo-zurich-bd;
                text-transform: none;
            }
        }
    }

    // Nav functions
    .nav--functions.nav--functions-small {
        position: absolute;
        bottom: 1rem;
        right: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity $trans-time--s $trans-func--modal,
                    transform $trans-time--s $trans-func--modal;

        li {
            margin-left: 1.5rem;
        }

        a,
        span {
            @include typo-zurich;
            @include typo-zurich--0;
            text-transform: none;
            line-height: 1;

            &.is-active {
                @include typo-zurich-bd;
                text-transform: none;
            }
        }

        // No space for search
        @include breakpoint(three) {
            .nav-search {
                display: none;
            }
        }
    }

    // Small functions navi
    .is-scrolled-decent & .nav--functions.nav--functions-small {
        opacity: 1;
        pointer-events: all;
    }

    // Subnav is hovered
    .has-subnav:hover,
    .has-subnav.has-visible-subnav {
        .nav--sub-nav {
            max-height: 48rem;
            opacity: 0.97;
            z-index: 2;
        }
    }

    @include breakpoint(desktop) {
        .nav--functions {
            .nav-item-icon {
                display: none;
            }
        }
    }

    // Nav main is hovered
    .nav--main.is-hovered {
        & > ul > li {
            .main-link {
                color: $text-color--grey-light-1;
            }

            &.has-visible-subnav,
            &.is-focussed {
                .main-link {
                    @include typo-zurich-bd;
                    color: #000;
                }
            }
        }
    }

    // Search
    .nav-search {
        form {
            position: relative;
        }

        input[type="search"] {
            width: 18rem;
        }

        .btn {
            position: absolute;
            top: 50%;
            right: 0.5rem;
            margin-top: -0.6rem;

            .svg-ico {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }
}

/* Desktop transition & states
   ========================================================================== */
@include breakpoint(desktop) {
    /* show reduced navi */
    .is-scrolled-decent .header.header--main:not(.header--checkout):not(.header--signup) {
        position: fixed;
        top: -7.5rem;
        left: 0;
        width: 100%;
    }

    .is-scrolled-decent .outer-wrapper:not(.outer-wrapper--checkout):not(.outer-wrapper--signup):not(.outer-wrapper--customerinfo) {
        padding-top: 12rem;
    }
}
