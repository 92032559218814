.breadcrumb {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .breadcrumb__item {
        @include typo-zurich;
        @include typo-zurich--1;
        display: inline-block;

        &:not(:last-child) {
            &:after {
                content: '/';
                display: inline-block;
                margin: 0 0.5rem;
            }
        }
    }
}