////
/// Collection of useful mixins
/// @group Mixins
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

/// Shorthand Method for keyframes
///
/// @group humans-machines
/// @content
/// @param {string} $name - The name for the keyframes
@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

/// Mixin for input placeholder styling
///
/// @group humans-machines
/// @content
@mixin placeholder {
    ::placeholder {@content}
}

/// Mixin for nested input placeholder styling
///
/// @group humans-machines
/// @content
@mixin placeholder-nested {
    &::placeholder {@content}
}

/// Mixin for including mobile safe hover effects
///
/// @group humans-machines
/// @content
@mixin hover {
    .no-mobile.no-touch & {
        &:hover {
            @content;
        }
    }
}

/// Mixin for applying mobile specific css
///
/// @group humans-machines
/// @content
@mixin no-hover {
    .mobile &,
    .touch & {
        @content;
    }
}

/// Mixin for pseudo elements
///
/// @group humans-machines
/// @param {string} $display [inline-block] - How to display the pseudo element
/// @param {string} $pos     [absolute]     - Position of the pseudo element
/// @param {string} $content [empty]        - Initial content to set
@mixin pseudo($display: inline-block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

/// Mixin to truncate a text
///
/// @group humans-machines
/// @param {number} $truncation-boundary - The max width for truncate
@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/// Mixin for a pseudo list like a navigation
///
/// @group humans-machines
@mixin pseudo-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/// Mixin for a clearfix, since flexbox we might not need it anymore
///
/// @group humans-machines
@mixin clearfix {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

/// Mixin to maintain an aspect ratio of a container
///
/// @group humans-machines
/// @param {number} $width - The width ratio of the container
/// @param {number} $height - The height ratio of the container
@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

/// Mixin for fluid typography
///
/// @group humans-machines
/// @param {list} $properties - The properties to apply the calculation e.g. font-size, padding, ...
/// @param {number} $min-vw - The min viewport width in pixel
/// @param {number} $max-vw - The max viewport width in pixel
/// @param {number} $min-value - The min value to apply
/// @param {number} $max-value - The max value to apply
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
        }
    }

    @media screen and (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}

/// Mixin for applying an order index for the given selector
///
/// @group humans-machines
/// @param {list} $selectors - The list with the quoted selectors
@mixin flex-order($selectors) {
    $counter: 1;
    @each $selector in $selectors {
        $selector: unquote($selector);

        #{$selector} {
            order: $counter;
        }

        $counter: $counter + 1;
    }
}

/// Mixin for setting up a fullsize box in an relativate element
///
/// @group humans-machines
@mixin fullsize-box() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/// Nice mixins to have a delay cascade
///
/// @group humans-machines
@mixin transition-delay($timing: 0.1s, $items: 20) {
    @for $i from 0 through $items {
        &:nth-child(#{$i}) {
            transition-delay: $i * $timing;
        }
    }
}

@mixin hideable-block() {
    overflow: hidden;
    transition: max-height $trans-time--m $trans-func--default,
                opacity $trans-time--m $trans-func--default,
                margin $trans-time--m $trans-func--default;

}

@mixin hideable-block-state($visible: true) {
    @if $visible == true {
        opacity: 1;
        max-height: 16rem;
    } @else {
        max-height: 0;
        opacity: 0;
        margin-bottom: 0;
    }
}

/// Hide Elements Visually without loosing accessebility
/// Ref: https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
///
/// @group humans-machines
@mixin hide {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px !important;
    padding: 0;
    border: 0;
}

/// Mixin to fade elements against background with a gradient
///
/// @group humans-machines
/// @param {number} $side - side of the cover element
/// @param {number} $width - width of the overlaying gradient
/// @param {number} $color - background-color of the overlaying gradient
@mixin fade-cover($side: right, $size: 1.6em, $color: $back-color--default) {
    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        pointer-events: none;

        // gradient on the right - default
        @if $side == 'right' {
            background: linear-gradient(to right, rgba($color, 0), $color);
            top: 0;
            bottom: 0;
            width: $size;
            right: 0;

            // gradient on the left
        } @else if $side == 'left' {
            background: linear-gradient(to right, $color, rgba($color, 0));
            left: 0;
            top: 0;
            bottom: 0;
            width: $size;

            // gradient on the bottom
        } @else if $side == 'bottom' {
            background: linear-gradient(to bottom, $color, rgba($color, 0));
            left: 0;
            right: 0;
            bottom: $size * -1;
            height: $size;

            // gradient on the top
        } @else if $side == 'top' {
            background: linear-gradient(to top, $color, rgba($color, 0));
            left: 0;
            right: 0;
            top: $size * -1;
            height: $size;
        }
    }
}