.cms-container {
    margin-top: $margin--m;
    margin-bottom: $margin--l;
    text-align: left;

    h1 {
        margin-bottom: $margin--m;

        @include breakpoint(mobile) {
            margin-bottom: $margin--s;
        }
    }

    h3 {
        margin-bottom: $margin--s;
    }

    p {
        @include typo-letter;
        @include typo-letter--text;
        text-transform: none;

        &.is-hint {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
        }
    }

    ul.bullet-list {
        & > li {
            @include typo-letter;
            @include typo-letter--text;
            text-transform: none;
            position: relative;
            display: block;
            padding-left: 2rem;
            padding-right: 0;
        }

        & > li:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '\2f';
            text-align: left;
            display: inline-block;
            text-indent: 0;
        }

        li + li {
            margin-top: 1rem;
        }
    }

    .cms-content-header,
    .cms-content-text {
        max-width: 3 * $module-width;
    }
}

// USA shipping map
.shipping-map {
    margin-top: $margin--m;
    margin-bottom: $margin--m;

    .zone-1,
    .zone-2,
    .zone-group-1,
    .zone-group-2 {
        opacity: 1;
        transition: opacity $trans-time--s $trans-func--linear;
        cursor: pointer;
    }

    .zone-1,
    .zone-box-1 {
        fill: #A5A5A5;
    }

    .zone-2,
    .zone-box-2 {
        fill: #7B7B7B;
    }

    .zone-text {
        font-family: ZurichBT-Light, Arial, Helvetica, sans-serif;
        font-size: 16px;
        text-transform: uppercase;
    }

    .no-touch & {
        &.is-focussed--zone-1 {
            .zone-1 {
                &:not(:hover) {
                    opacity: 0.9;
                }
            }

            .zone-2,
            .zone-group-2 {
                opacity: 0.2;
            }
        }

        &.is-focussed--zone-2 {
            .zone-2 {
                &:not(:hover) {
                    opacity: 0.9;
                }
            }

            .zone-1,
            .zone-group-1 {
                opacity: 0.2;
            }
        }
    }

    @include breakpoint(two) {
        .zone-box-1,
        .zone-box-2 {
            width: 28px;
            height: 28px;
        }

        .zone-group-1 {
            transform: translateY(-10px);

            .zone-text {
                transform: translate3d(50px, 562px, 0);
            }
        }

        .zone-group-2 {
            transform: translateY(10px);

            .zone-text {
                transform: translate3d(50px, 590px, 0);
            }
        }

        .zone-text {
            font-size: 20px;
        }
    }

    @include breakpoint(one) {
        .zone-box-1,
        .zone-box-2 {
            width: 36px;
            height: 36px;
        }

        .zone-group-1 {
            transform: translateY(-20px);

            .zone-text {
                transform: translate3d(60px, 570px, 0);
            }
        }

        .zone-group-2 {
            transform: translateY(10px);

            .zone-text {
                transform: translate3d(60px, 598px, 0);
            }
        }

        .zone-text {
            font-size: 36px;
        }
    }
}

.shipping-costs-hints {
    margin-top: $margin--s;

    p {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
        margin: 0;
    }

    p + p {
        margin-top: $margin--xxs;
    }
}