// Definition of the productdetailpage
.product-detail {
    margin: 4rem auto 12rem auto;
    text-align: left;

    @include breakpoint(five) {
        width: 5 * $module-width;
    }

    @include breakpoint(four) {
        width: 4 * $module-width;
    }

    @include breakpoint(three) {
        width: 3 * $module-width;
    }

    @include breakpoint(mobile) {
        width: 100%;
        margin-top: 0;
    }

    article {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .detail-information {
            order: 1;
        }

        .detail-media-control--mobile {
            order: 2;
        }

        .detail-media {
            order: 3;
        }

        @include breakpoint(mobile) {
            flex-direction: column;

            .detail-information {
                order: 3;
            }

            .detail-media-control--mobile {
                order: 2;
            }

            .detail-media {
                order: 1;
            }
        }
    }

    // Back to overview
    .detail-back-to-overview {
        display: inline-block;
        position: absolute;
        top: -3rem;
        right: 0;

        @include breakpoint(mobile) {
            top: -2rem;
            left: 50%;
            right: auto;
            margin-left: auto;
            margin-right: auto;
            text-align: right;
            transform: translateX(-50%);
        }

        // Hide for the moment on mobile
        //@include breakpoint(two) {
        //    width: 2 * $module-width;
        //}
        //
        //@include breakpoint(one) {
        //    width: $module-width;
        //}

        @include breakpoint(mobile) {
            display: none;
        }
    }

    // Images and videos
    .detail-media {
        display: block;
        width: 4 * $module-width;
        position: relative;

        // Container for mobile
        .detail-media-elements {
            @include breakpoint(desktop) {
                margin-top: -1rem;
            }

            @include breakpoint(two) {
                width: 100%;
                height: 2 * $module-width;
                overflow: hidden;
            }

            @include breakpoint(one) {
                width: 100%;
                height: 1 * $module-width;
                overflow: hidden;

                .flickity-viewport {
                    width: 100%;
                }
            }
        }

        // General media element
        .detail-media-element {
            position: relative;
            width: 4 * $module-width;
            height: 4 * $module-height;

            @include breakpoint(four) {
                width: 3 * $module-width;
                height: 3 * $module-height;
            }

            @include breakpoint(three) {
                width: 2 * $module-width;
                height: 2 * $module-height;
            }

            @include breakpoint(two) {
                width: 100%;
                height: 2 * $module-width;
            }

            @include breakpoint(one) {
                box-sizing: border-box;
                width: 100%;
                height: 1 * $module-width;
            }
        }

        // Image media element
        .detail-media-element--image {
            @include breakpoint(desktop) {
                margin-bottom: 2rem;

                .module {
                    box-sizing: border-box;
                    padding: 1rem 0 1rem 2rem;
                }

                img {
                    mix-blend-mode: multiply;
                    cursor: none;
                }

                picture {
                    background-color: $back-color--detail-image;
                }
            }

            .media-box {
                width: 100%;
                height: 100%;
            }
        }

        // Model Portraits (Mobile)
        .detail-media-element--image.detail-media-element--model-portrait {
            @include breakpoint(desktop) {
                display: none;
            }

            @include breakpoint(mobile) {
                padding: 0;
                box-sizing: border-box;

                img {
                    //transform: none;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform-origin: 50% 25%;
                    object-position: 50% 50%;
                    transform: scale3d(1.2, 1.2, 1);
                }
            }
        }

        // Model Portraits (Desktop)
        .detail-model-portraits {
            position: relative;
            margin-bottom: 3rem;

            @include breakpoint(mobile) {
                display: none;
            }

            .detail-media-element-model-portraits-slideshow {
                position: absolute;
                top: 1rem;
                left: 2rem;
                right: 0;
                bottom: 1rem;
            }

            .detail-media-element-model-portraits-slideshow__slide {
                width: 100%;
                height: 100%;
            }

            .detail-media-element--model-portraits {
                margin-bottom: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform-origin: 50% 25%;
                object-position: 50% 50%;
                transform: scale3d(1.2, 1.2, 1);
            }

            .detail-model-portraits__description {
                margin-top: 1rem;
                padding-left: 2rem;

                p {
                    @include typo-zurich;
                    @include typo-zurich--1;
                    text-transform: none;
                }
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }

        // Video media element
        .detail-media-element--video {
            height: auto;
            padding-bottom: 56.25%;

            .module--video {
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                pointer-events: none;
                opacity: 0;
                transition: opacity $trans-time--m $trans-func--easing;

                @include breakpoint(desktop) {
                    padding: 1rem 0 1rem 2rem;
                }

                @include breakpoint(one) {
                    top: 4rem;
                    width: 1 * $module-width;
                    height: 1 * $module-height;
                }
            }

            video {
                position: relative;
                width: 100%;
                height: auto;
                background-color: transparent;
            }

            // Video states
            &.has-active-video-male {
                .module--video-male {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            &.has-active-video-female {
                .module--video-female {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            &.has-active-video-unisex {
                .module--video-unisex {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }

        // Shades hint
        .detail-media-element-hint {
            margin-bottom: 2rem;
            padding-left: 2rem;

            p {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
                display: inline-block;
                margin-right: 1rem;
                margin-bottom: 0;
            }
        }

        // Video controls for switching Male/Femal/Unisex
        .detail-video-controls {
            position: relative;
            padding-left: 2rem;
            margin-top: 1rem;

            .btn:not(:last-child) {
                margin-right: 1rem;
            }

            &.has-active-video-male {
                .btn[data-target='male'] {
                    @include typo-zurich-bd;
                    text-transform: none;
                }
            }

            &.has-active-video-female {
                .btn[data-target='female'] {
                    @include typo-zurich-bd;
                    text-transform: none;
                }
            }

            &.has-active-video-unisex {
                .btn[data-target='unisex'] {
                    @include typo-zurich-bd;
                    text-transform: none;
                }
            }

            // Hide video controls on mobile
            @include breakpoint(mobile) {
                display: none;
            }
        }

        // Video information - Showing the certain version the model is wearing
        .detail-video-information {
            position: relative;
            padding: 0 2rem;
            margin-top: 1rem;

            .video-information {
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 2rem;
                padding-left: 2rem;
                pointer-events: none;
                opacity: 0;
                transition: opacity $trans-time--s $trans-func--default;
            }

            p {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
                margin-bottom: 0;
            }

            &.has-active-video-male {
                .video-information--male {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            &.has-active-video-female {
                .video-information--female {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            &.has-active-video-unisex {
                .video-information--unisex {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            // Hide video information on mobile
            @include breakpoint(mobile) {
                display: none;
            }
        }

        // Label for Media Elements
        .detail-media-element__label {
            position: absolute;
            top: 3rem;
            right: 2rem;

            p {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
            }

            @include breakpoint(three) {
                top: 2.5rem;
                right: 1.5rem;

                p {
                    @include typo-zurich--0;
                }
            }

            @include breakpoint(two) {
                top: 1rem;
                right: 1rem;

                p {
                    @include typo-zurich--0;
                }
            }

            @include breakpoint(one) {
                top: 1rem;
                right: 0;

                p {
                    @include typo-zurich--s2;
                }
            }
        }

        // Demo Lens + Asia Fit
        .detail-media-element__demo-lens,
        .detail-media-element__asia,
        .detail-media-element__nose-fit,
        .detail-media-element__free-service-part,
        .detail-media-element__core-selection {
            position: absolute;
            top: 3.5rem;
            right: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 6rem;
            pointer-events: none;

            .svg-ico {
                width: 3.5rem;
                height: 3.5rem;
            }

            p {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
                text-align: center;
                margin-top: $margin--xxs;
                margin-bottom: 0;
                line-height: 1.3;
            }

            @include breakpoint(three) {
                top: 2.5rem;
                right: 1rem;

                .svg-ico {
                    width: 3rem;
                    height: 3rem;
                }

                p {
                    @include typo-zurich--0;
                    line-height: 1.3;
                }
            }

            @include breakpoint(two) {
                top: 1rem;
                right: 1rem;

                .svg-ico {
                    width: 3rem;
                    height: 3rem;
                }

                p {
                    @include typo-zurich--0;
                    line-height: 1.3;
                }
            }

            @include breakpoint(one) {
                top: 0;
                right: 0;

                .svg-ico {
                    width: 1.8rem;
                    height: 1.8rem;
                }

                p {
                    @include typo-zurich--s2;
                    line-height: 1.3;
                }
            }
        }

        .detail-media-element__nose-fit-icon {
            text-align: center;
            margin-top: $margin--xs;

            svg {
                width: 3.5rem;
            }

            @include breakpoint(one) {
                margin-top: $margin--xxs;

                svg {
                    width: 2.5rem;
                }
            }
        }

        .detail-media-element__demo-lens {
            width: 10rem;

            @include breakpoint(one) {
                width: 8rem;
            }
        }

        .detail-media-element__core-selection {
            width: 9rem;

            @include breakpoint(one) {
                width: 7rem;
            }
        }

        // Responsive rendering of the elements
        @include breakpoint(desktop) {
            float: right;
        }

        @include breakpoint(four) {
            width: 3 * $module-width;
        }

        @include breakpoint(three) {
            width: 2 * $module-width;
        }

        @include breakpoint(two) {
            width: 100%;
            height: 2 * $module-width;
        }

        @include breakpoint(one) {
            width: 100%;
            height: 1 * $module-width;
        }

        @include breakpoint(mobile) {
            margin-bottom: 1rem;
        }
    }

    // Aside -> Information of the article
    .detail-information {
        display: block;
        width: $module-width;
        position: relative;

        // General subheadlines
        h3 {
            @include typo-letter;
            @include typo-letter--1;
            line-height: 1;
        }

        .detail-information-section {
            position: relative;
            margin-bottom: 4rem;

            &.toggle-panel-group {
                margin-top: 5rem;
            }
        }

        // Fixable detail information
        .detail-information-fixable {
            position: relative;
            background-color: $back-color--default;
            z-index: 3;

            @include breakpoint(desktop) {
                width: $module-width;

                @supports (position: sticky) {
                    position: sticky;
                    top: 5.5rem;

                    &:after {
                        content: '';
                        position: absolute;
                        top: -1rem;
                        left: 0;
                        width: 100%;
                        height: 1rem;
                        background-color: $back-color--default;
                    }
                }
            }

            // Is stuck state
            &.is-stuck {
                @include breakpoint(desktop) {
                    .detail-information-section--main {
                        margin-bottom: 1.5rem;
                    }

                    .detail-information-section--price {
                        margin-bottom: 1.5rem;
                    }

                    .detail-information-section--promotion {
                        margin-bottom: 1.5rem;
                    }
                }
            }
        }

        // Main information
        .detail-information-section--main {
            margin-bottom: 3.25rem;
            transition: transform $trans-time--m $trans-func--easing,
                        margin $trans-time--m $trans-func--easing;

            .article-collection {
                @include typo-letter;
                @include typo-letter--1;
                line-height: 1;
                margin-bottom: 1.5rem;
            }

            .article-name {
                @include typo-zurich-bd;
                @include typo-zurich--4;
                display: flex;
                align-items: center;
                line-height: 1;
                margin-bottom: 1.5rem;

                @include breakpoint(one) {
                    @include typo-zurich--3;
                    line-height: 1;
                }
            }

            .article-color,
            .article-unit,
            .article-number {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
                margin-bottom: 0;
            }
        }

        // Price and shipping
        .detail-information-section--price {
            display: block;
            margin-bottom: 3.5rem;
            transition: transform $trans-time--m $trans-func--easing,
                        margin $trans-time--m $trans-func--easing;

            .article-price-list {
                @include pseudo-list;
                display: flex;
                width: 100%;

                .article-price {
                    display: inline-block;
                    flex-basis: 50%;

                    &[aria-hidden='true'] {
                        display: none;
                    }

                    &.article-price--service-part {
                        flex-basis: 100%;

                        .article-price-value {
                            color: $text-color--service-part;
                        }
                    }
                }

                .article-price-value {
                    @include typo-zurich-bd;
                    @include typo-zurich--4;
                    white-space: nowrap;
                    line-height: 1;
                    display: block;

                    &.article-price-value--s {
                        @include typo-zurich--4-s;
                        line-height: 1;
                    }

                    @include breakpoint(one) {
                        @include typo-zurich--3;
                        line-height: 1;

                        &.article-price-value--s {
                            @include typo-zurich--3;
                            line-height: 1;
                        }
                    }
                }

                .article-price-hint {
                    @include typo-zurich;
                    @include typo-zurich--0;
                    text-transform: none;
                    display: block;
                    margin-top: $margin--xxs;

                    &[aria-hidden='true'] {
                        display: none;
                    }

                    &.article-price-hint--service-part {
                        display: flex;
                        align-items: center;

                        > span {
                            margin: 0 $margin--xxs;
                        }

                        > a {
                            line-height: 1;
                        }

                        .svg-ico {
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                }

                @include breakpoint(two) {
                    justify-content: flex-end;
                }

                @include breakpoint(one) {
                    flex-direction: column;

                    .article-price:last-child:not(:first-child) {
                        margin-top: $margin--xxs;
                    }
                }
            }

            .article-price-information {
                @include typo-zurich;
                @include typo-zurich--s2;
                text-transform: none;
                margin-bottom: 0;
                text-align: right;
            }

            &[aria-hidden='true'] {
                display: none;
            }

            // Display items as flexbox
            @include breakpoint(desktop) {
                display: flex;

                .article-price {
                    width: 11rem;
                }

                .article-price-information {
                    width: 13rem;
                }

                .article-price-information {
                    align-self: center;
                }
            }

            @include breakpoint(two) {
                .article-price {
                    text-align: right;
                    margin-bottom: 1.5rem;
                }
            }
        }

        // Price/Shipping information
        .detail-information-section--price,
        .detail-information-section--price-information-mobile {
            .article-price-information {
                @include typo-zurich;
                @include typo-zurich--s2;
                text-transform: none;
                margin-bottom: 0;
                text-align: right;
            }
        }

        // Article could be bought
        &.is-buyable {
            // Detail information wrapper, to display elements properly
            .detail-information-wrapper--main {
                @include breakpoint(mobile) {
                    display: flex;

                    .detail-information-section--price {
                        padding-top: 2.65rem;
                    }

                    .detail-information-section--optical {
                        width: 100%;
                    }
                }

                @include breakpoint(two) {
                    .detail-information-section--main,
                    .detail-information-section--price {
                        width: $module-width;
                    }
                }

                @include breakpoint(one) {
                    .detail-information-section--main {
                        width: $module-width * 0.6;
                    }

                    .detail-information-section--price {
                        width: $module-width * 0.4;
                        text-align: right;

                        .article-price-information {
                            display: none;
                        }
                    }
                }
            }
        }

        // Promotion
        .detail-information-section--promotion {
            text-align: left;
            opacity: 1;
            max-height: 8rem;
            margin-bottom: 3.5rem;
            overflow: hidden;
            transition: margin $trans-time--m $trans-func--easing;

            h3,
            p {
                color: $text-color--promotion;
            }

            h3 {
                @include typo-zurich;
                @include typo-zurich--2;
                line-height: 1.1;
                margin-bottom: 0.25rem;
            }

            p {
                @include typo-zurich;
                @include typo-zurich--2;
                text-transform: none;
                margin-bottom: 0;
            }
        }

        // Add to basket
        .detail-information-section--actions-primary {
            margin-bottom: 5rem;

            @include breakpoint(one) {
                margin-bottom: 1rem;
            }

            form + button,
            button + button {
                margin-top: 1rem;
            }
        }

        // Price info for mobile
        .detail-information-section--price-information-mobile {
            display: none;

            @include breakpoint(one) {
                display: block;

                .article-price-information {
                    text-align: center;
                }
            }
        }

        // Optical information
        .detail-information-section--optical {
            margin-bottom: 3.5rem;

            p {
                @include typo-zurich;
                @include typo-zurich--0;
                text-transform: none;
                margin-bottom: 0;
            }
        }

        // Back to overview
        .detail-information-section--overview {
            @include breakpoint(desktop) {
                display: none;
            }
        }

        // Toggle panel group
        .toggle-panel-group {
            margin-bottom: 2.25rem;
        }

        // Additional padding to set
        .toggle-panel--size-guide {
            .toggle-panel-body {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        .toggle-panel--prices {
            h3 {
                display: flex;
            }

            .details-selected-price-label,
            .details-selected-price-value {
                position: relative;
                display: inline-block;
            }

            .details-selected-price-label {
                padding-right: $padding--xxs;
            }

            .details-selected-price-value {
                flex: 1 1 auto;
                overflow: hidden;
                white-space: nowrap;

                &:after {
                    @include pseudo();
                    top: 0;
                    right: 0;
                    width: 4rem;
                    height: 100%;
                    background: linear-gradient(to right, rgba($back-color--default, 0), $back-color--default 50%);
                }
            }
        }

        // Subarticles / Color Variants
        .detail-article-list {
            @include clearfix;

            .detail-article-list__item {
                float: left;
                position: relative;
                display: inline-block;
                width: 33%;
                height: $module-height / 3;
                border: solid 1px white;
                box-sizing: border-box;
                margin-bottom: 1px;
                transition: border $trans-time--m $trans-func--default;

                & > a,
                & > div {
                    box-sizing: border-box;
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                // No stock state
                &.has-no-stock {
                    overflow: hidden;
                    cursor: not-allowed;

                    &:before,
                    &:after {
                        @include pseudo();
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        pointer-events: none;
                    }

                    &:before {
                        z-index: 1;
                        background-color: $back-color--out-of-stock;
                    }

                    &:after {
                        z-index: 2;
                        background: linear-gradient(
                            to left top,
                            transparent 49.4%,
                            $border-color--light-5 49.5%,
                            $border-color--light-5 50.5%,
                            transparent 50.6%
                        );
                    }
                }

                @include breakpoint(two) {
                    width: $module-width / 2;
                    height: $module-height / 2;
                }
            }

            .detail-article-list__item.is-active,
            .detail-article-list__item:hover {
                border-color: $border-color--light-5;
            }

            img {
                width: 100%;
                max-height: 100%;
            }

            // Hovering colour item
            &:hover {
                .detail-article-list__item.is-active:not(:hover) {
                    border-color: #fff;
                }
            }
        }

        // Price Selection
        .details-price-selection-list {
            @include pseudo-list;
            display: block;

            .details-price-selection {
                display: block;

                &:not(:last-child) {
                    margin-bottom: $margin--xxs;
                }
            }
        }

        // Product features / Collection allocation
        .detail-list-product-features,
        .detail-list-collection-allocation {
            li {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
                display: block;
                position: relative;
                padding-left: 1rem;
                margin-bottom: 0.25rem;

                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    content: '\B7';
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }

        // Frame pictograms
        .frame-pictograms {
            @include clearfix;

            .frame-pictogram {
                display: inline-block;
                width: 10.5rem;
                float: left;

                &.frame-pictogram--side {
                    margin-right: 1rem;
                }

                svg {
                    width: 100%;

                    text {
                        font-family: ZurichBT-Light, Arial, Helvetica, sans-serif;
                        text-transform: uppercase;
                        font-weight: 100;
                    }
                }

                @include breakpoint(two) {
                    width: 16rem;
                }
            }
        }

        // Product description
        .product-description {
            p {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
            }
        }

        // About collection
        .about-collection {
            p {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
            }
        }

        // Delivery and Returns
        .delivery-returns {
            p {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
            }

            a:first-of-type {
                margin-right: 1rem;
            }
        }

        // Share Wishlish etc.
        .detail-information-section--actions-secondary {
            li {
                display: block;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }

            // Shades
            .action-frame-shades {
                position: relative;
            }

            // Share dialog
            .action-share {
                .share-links {
                    display: block;
                    margin-top: 1rem;

                    li {
                        transform: translate3d(0, -0.25rem, 0);
                        transition: opacity $trans-time--m $trans-func--default,
                                    transform $trans-time--m $trans-func--default;
                        display: inline-block;
                        opacity: 0;
                        pointer-events: none;

                        &:not(:last-child) {
                            margin-right: 1rem;
                        }

                        &:nth-child(2) {
                            transition-delay: 0.1s;
                        }

                        &:nth-child(3) {
                            transition-delay: 0.2s;
                        }
                    }

                    &.is-visible {
                        li {
                            transform: translate3d(0, 0, 0);
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }

        // Responsive rendering of article information
        @include breakpoint(desktop) {
            float: left;
        }

        @include breakpoint(two) {
            width: 2 * $module-width;
            margin-left: auto;
            margin-right: auto;
        }

        @include breakpoint(one) {
            width: $module-width;
            margin-left: auto;
            margin-right: auto;
        }
    }

    // Fit Relation: Standard | Asia
    .detail__fit-relation {
        display: flex;
        align-items: center;
        margin-top: $margin--m;
        padding-bottom: $padding--s;

        .text-link {
            @include typo-zurich-bd;
            text-transform: uppercase;
        }

        .product-icon-list {
            margin-left: $margin--xs;

            .svg-ico {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }

    // Asia Fit: Icon + Name
    .frame__fit {
        display: flex;
        align-items: center;
        margin-top: $margin--s;

        .frame__fit__icon {
            width: 6rem;

            svg {
                width: 100%;
            }
        }

        .frame__fit__name {
            flex: 1 1 auto;
            padding-left: $padding--s;

            p {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
                margin-bottom: 0;
            }
        }

        @include breakpoint(two) {
            .frame__fit__icon {
                width: 8rem;
            }
        }
    }

    // Add some additional rules for service parts
    &.product-detail--service-part {
        @include breakpoint(mobile) {
            .detail-media-element--image {
                img {
                    transform: scale(1.1);
                }
            }
        }

        &[data-category='nose-pads'] {
            .detail-article-list__item img {
                transform: scale(1.7);
            }
        }

        &[data-category='nose-bridges'] {
            .detail-article-list__item img {
                transform: scale(1.3);
            }
        }
    }
}

// Additions for product detail page
.product-detail-additions {
    // Hover controls
    @include breakpoint(desktop) {
        .detail-media-elements-hover {
            position: fixed;
            top: 0;
            left: 0;
            display: inline-block;
            pointer-events: none;
            z-index: 102;
            width: 3rem;
            height: 3rem;

            .action-indicator {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: opacity 0.1s $trans-func--default;
            }

            .svg-ico {
                width: 100%;
                height: 100%;
            }

            &[aria-hidden='true'] {
                display: none;
            }

            &[data-action='scroll-up'] {
                .action-indicator--scroll-up {
                    opacity: 1;
                }
            }

            &[data-action='scroll-down'] {
                .action-indicator--scroll-down {
                    opacity: 1;
                }
            }

            &[data-action='zoom-in'] {
                .action-indicator--zoom-in {
                    opacity: 1;
                }
            }

            &[data-action='zoom-out'] {
                .action-indicator--zoom-out {
                    opacity: 1;
                }
            }
        }
    }

    @include breakpoint(mobile) {
        .detail-media-elements-hover {
            display: none;
        }
    }

    // Image zoom
    .detail-media-element-zoom {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        z-index: 101;
        pointer-events: none;
        transition: opacity $trans-time--s $trans-func--default 0s,
                    visibility 0s $trans-func--default $trans-time--s;

        .detail-media-element-zoom-content {
            transform: translate3d(0, -50%, 0);
            position: absolute;
            top: 50%;
            left: 0;
            height: auto;
            width: 100%;
            padding-bottom: 66.66%;
            transition: opacity $trans-time--s $trans-func--default;
            opacity: 1;

            .module {
                position: absolute;
                top: 0;
                left: 0;
            }

            .media-box {
                width: 100%;
                height: 100%;
            }

            img {
                mix-blend-mode: multiply;
                cursor: none;
            }
        }

        .detail-media-element-zoom-close {
            position: absolute;
            top: 2rem;
            right: 2rem;

            .svg-ico {
                width: 3rem;
                height: 3rem;
            }
        }

        .detail-media-element-zoom-pagination {
            display: flex;
            position: absolute;
            left: 0;
            bottom: 6rem;
            width: 100%;
            justify-content: center;

            ul {
                display: flex;
                justify-content: center;
                list-style: none;
                margin: 0;

                &:hover li.is-active:not(:hover) {
                    border-color: transparent;
                }
            }

            li {
                display: inline-block;
                width: $module-width / 3;
                height: $module-height / 3;
                border: solid 1px transparent;
                transition: border $trans-time--m $trans-func--default;


                &.is-active,
                &:hover {
                    border-color: #7f7f7f;
                }
            }

            img {
                width: 100%;
            }
        }

        &.is-visible {
            visibility: visible;
            opacity: 1;
            pointer-events: all;
            transition: opacity $trans-time--s $trans-func--default;
        }

        &.is-changing {
            .detail-media-element-zoom-content {
                opacity: 0;
            }
        }

        @include breakpoint(desktop) {
            background-color: $back-color--detail-image;

            picture {
                background-color: $back-color--detail-image;
            }

            .detail-media-element-zoom-pagination {
                li {
                    background-color: $back-color--detail-image;
                }

                img {
                    mix-blend-mode: multiply;
                }
            }
        }

        @include breakpoint(mobile) {
            background-color: #fff;

            .detail-media-element-zoom-pagination {
                bottom: 2rem;

                li {
                    background-color: #fff;
                    width: $module-width / 4;
                    height: $module-height / 4;
                }
            }
        }
    }
}

// Detail media control
.product-detail,
.product-detail-additions {
    // Default styling
    .detail-media-control {
        display: none;

        ul {
            display: inline-block;
        }

        li {
            display: block;
            text-align: center;
        }
    }

    // Desktop control
    @include breakpoint(desktop) {
        .detail-media-control--desktop {
            transform: translate3d(0, -50%, 0);
            display: block;
            position: fixed;
            top: 50%;
            right: 3rem;
            z-index: 10;

            li {
                margin: 1rem 0;

                &.has-dot {
                    height: 10px;
                }

                //&:last-child {
                //    margin-top: 2rem;
                //}
            }
        }

        .detail-media-control--mobile {
            display: none;
        }
    }

    // Mobile control
    @include breakpoint(mobile) {
        .detail-media-control--mobile {
            display: block;
            text-align: center;
            margin: -4rem auto 3rem auto;
            width: $module-width;
            z-index: 3;

            ul {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 100%;
                height: 2rem;
            }

            li {
                align-self: center;
                padding: 0 0.5rem;

                &.has-dot {
                    height: 10px;
                }

                &.media-control-video-label {
                    padding-right: 0;
                }

                span,
                .btn--link {
                    @include typo-zurich;
                    @include typo-zurich--0;
                    line-height: 1.1;
                    text-transform: none;
                }
            }

            .btn.is-active.is-active-video {
                @include typo-zurich-bd;
                text-transform: none;
            }
        }

        .detail-media-control--desktop {
            display: none;
        }
    }

    @include breakpoint(two) {
        .detail-media-control--mobile {
            width: 2 * $module-width;
        }
    }
}
