// scss-lint:disable MergeableSelector

// Resizing for fontsizes
html {
    // Apply fallback fontsize
    font-size: 10px;

    @include fluid-type(font-size, 280px, 398px, 10.5px, 15px);
    @include fluid-type(font-size, 480px, 753px, 9px, 14.25px);
    @include fluid-type(font-size, 760px, 1112px, 9.5px, 14px);
    @include fluid-type(font-size, 1180px, 1540px, 10.5px, 13.5px);
    @include fluid-type(font-size, 1720px, 1900px, 12px, 13.5px);

    scroll-padding-top: $header-height--mobile + 2rem;
}

body {
    background-color: $back-color--default;
    margin: 0;
    padding: 0;
    font-family: ZurichBT-Light, Arial, Helvetica, sans-serif;
    font-weight: 100;
    color: $text-color--default;
    overflow-y: scroll;
}

.inner-wrapper {
    min-height: 100vh;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.outer-wrapper--checkout .inner-wrapper,
.outer-wrapper--signup .inner-wrapper {
    min-height: calc(100vh - 8rem);
}

.outer-wrapper--customerinfo .inner-wrapper {
    min-height: calc(100vh - #{$margin--m});
}

.outer-wrapper {
    max-width: 100%;
    //overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 4.5rem;

    @include breakpoint(threefive) {
        padding-top: 0;

        &.outer-wrapper--checkout,
        &.outer-wrapper--signup {
            padding-top: 8rem;
        }

        &.outer-wrapper--customerinfo {
            padding-top: $margin--m;
        }
    }
}

.site-container {
    transform: translate3d(0, 0, 0);
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    transition: margin $trans-time--modal $trans-func--modal,
                opacity $trans-time--modal $trans-func--modal,
                transform $trans-time--overlay $trans-func--overlay;
}

.mykita-container {
    width: $module-width;
    margin: auto;
    position: relative;
    text-align: center;

    @include breakpoint(two) {
        width: $module-width * 2;
    }

    @include breakpoint(three) {
        width: $module-width * 3;
    }

    @include breakpoint(four) {
        width: $module-width * 4;
    }

    @include breakpoint(five) {
        width: $module-width * 5;
    }
}

.mykita-container--small {
    width: $module-width;
    margin: auto;
    position: relative;
    text-align: center;

    @include breakpoint(two) {
        width: $module-width * 2;
    }

    @include breakpoint(three) {
        width: $module-width * 3;
    }

    @include breakpoint(four) {
        width: $module-width * 3;
    }

    @include breakpoint(five) {
        width: $module-width * 3;
    }
}

.mykita-container > .mykita-container--small {
    margin-left: 0;
    margin-right: 0;
}

// Container spacing
.quarterSpace,
.space--quarter {
    margin-bottom: 4rem; /* half height of a quarter */

    @include breakpoint(one) {
        margin-bottom: 2rem;
    }
}

.quarterSpaceMobile,
.space--quarter-mobile {
    margin-bottom: 4rem; /* half height of a quarter */

    @include breakpoint(one) {
        margin-bottom: 0;
    }
}

.quarterSpaceTop {
    margin-top: 4rem; /* half height of a quarter */

    @include breakpoint(one) {
        margin-top: 2rem;
    }
}

.halfSpace,
.space--half {
    margin-bottom: 8rem; /* half height of a small */

    @include breakpoint(one) {
        margin-bottom: 6rem;
    }
}

.halfSpaceMobile,
.space--half-mobile {
    margin-bottom: 8rem; /* half height of a small */

    @include breakpoint(one) {
        margin-bottom: 0;
    }
}

.halfSpaceTop,
.space--half-top{
    margin-top: 8rem; /* half height of a small */

    @include breakpoint(one) {
        margin-top: 6rem;
    }
}


.fullSpace,
.space--full {
    margin-bottom: 16rem; /* full height of a small */

    @include breakpoint(one) {
        margin-bottom: 12rem;
    }
}

.fullSpaceMobile,
.space--full-mobile {
    margin-bottom: 16rem; /* full height of a small */

    @include breakpoint(one) {
        margin-bottom: 4rem;
    }
}

.fullSpaceTop,
.space--full-top {
    margin-top: 16rem; /* full height of a small */

    @include breakpoint(one) {
        margin-top: 12rem;
    }
}

// Text/Element selection
::selection {
    background: #000;
    color: #fff;
}