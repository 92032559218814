.upload-file-list {
    display: block;
    list-style-type: none;

    &[aria-hidden="true"] {
        display: none;
    }
}

.upload-file-list__item {
    display: block;
    width: 100%;

    &:not(:first-child) {
       // margin-top: $margin--xs;
    }

    &[aria-hidden="true"] {
        display: none;
    }
}

.upload-file-list__group {
    display: flex;
    align-items: center;
    padding: $padding--xxs 0;
}

.upload-file-list__file {
    display: inline-block;
    line-height: 1;
    margin-right: $margin--xxs;

    .svg-ico {
        width: 1.2rem;
        height: 1.2rem;
    }
}

.upload-file-list__name {
    margin-bottom: 0;
    margin-right: 2rem;
}

.upload-file-list__dismiss {
    margin-left: auto;
}

.upload-file-list__progress {
    display: block;
    position: relative;
    width: 100%;
    height: 5px;
    background: $border-color--light-1;

    &:after {
        @include pseudo();
        height: 100%;
        background: $border-color--default;
    }

    @for $i from 0 through 100 {
        &[data-progress="#{$i}"]:after {
            width: $i + 0%;
        }
    }
}