.toggle-panel {
    position: relative;

    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    .toggle-panel-head {
        position: relative;
        padding-bottom: 1.25rem;

        a[role="button"] {
            position: relative;
            display: block;
            text-decoration: none;
        }

        .toggle-panel-icon {
            transition: transform $trans-time--m $trans-func--default;
            transform: scaleY(1);

            position: absolute;
            right: 0;
            top: 0.1rem;
            height: 1rem;
            width: 1rem;

            .svg-ico {
                width: 100%;
                height: 100%;
            }
        }
    }

    .toggle-panel-body {
        position: relative;
        max-height: 0;
        overflow: hidden;
        padding-bottom: 0;
        transition: max-height $trans-time--m $trans-func--easing,
                    padding $trans-time--m $trans-func--easing;
    }

    &[aria-expanded="true"],
    &.is-expanded,
    &.is-fixed {
        .toggle-panel-body {
            max-height: 64rem;
            padding-bottom: 3rem;
        }

        .toggle-panel-head {
            .toggle-panel-icon {
                transform: scaleY(-1);
            }
        }
    }

    &.is-fixed {
        .toggle-panel-body {
            max-height: none;
        }
    }
}
