.modal {
    display: inline-block;
    transform: translate3d(100%, 0, 0);
    position: fixed;
    z-index: map-get($z-index, modal);
    top: 0;
    right: 0;
    width: 2 * $module-width + 4rem;
    height: 100%;
    background-color: $back-color--modal;
    border-left: solid 2px #fff;
    transition: transform $trans-time--overlay $trans-func--overlay;
    overflow: hidden;

    .modal-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform: translate3d(2rem, 0, 0);
        transition: transform $trans-time--overlay $trans-func--overlay $trans-time--s,
        opacity $trans-time--overlay $trans-func--overlay $trans-time--s;

    }

    .modal-header {
        padding: 4rem 2rem 2rem 2rem;
    }

    .modal-footer,
    .modal-action {
        padding: 2rem 2rem 4rem 2rem;
    }

    .modal-content {
        padding: 0 2rem;
        flex: 1 1 auto;
        transform: translate3d(0, 0, 0);
        overflow-x: hidden;
        overflow-y: auto;
    }

    .modal-close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        z-index: 1;
        opacity: 0;
        transform: translate3d(2rem, 0, 0);
        transition: transform $trans-time--overlay $trans-func--overlay $trans-time--s,
                    opacity $trans-time--overlay $trans-func--overlay $trans-time--s;
    }

    .modal-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        margin: -16px 0 0 -16px;
        opacity: 1;
        pointer-events: none;
        background: $back-color--modal url('../images/loader/myk-loader-default.gif') 50% 50% no-repeat;
        background-size: 32px 32px;
        transition: opacity $trans-time--m $trans-func--default;
    }

    h3.is-multi-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .info-icon-link {
            margin-left: 0;
        }

        > *:not(:first-child) {
            margin-left: 1.5rem;
        }

        @include breakpoint(mobile) {
            font-size: 0.9rem;

            > *:not(:first-child) {
                margin-left: 0.75rem;
            }
        }
    }

    &:not(.has-footer) {
        .modal-footer {
            display: none;
        }
    }

    &.is-loaded {
        .modal-loader {
            opacity: 0;
        }
    }

    &[aria-hidden="false"] {
        transform: translate3d(0, 0, 0);

        &.is-loaded {
            .modal-close,
            .modal-container {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    @include breakpoint(mobile) {
        .modal-header {
            padding-top: 2rem;
        }

        .modal-footer,
        .modal-action {
            padding-bottom: 2rem;
        }
    }

    @include breakpoint(two) {
        width: $module-width + 4rem;
    }

    @include breakpoint(one) {
        width: $module-width;
    }
}

// Modal open state
.has-modal {
    overflow: hidden;

    .header.header--main,
    .footer.footer--main,
    .site-container,
    .filter-site-content.filter-site-content--sticky {
        transform: translate3d((($module-width * 2) + 4rem) * (-1), 0, 0);

        @include breakpoint(two) {
            transform: translate3d(($module-width + 4rem) * (-1), 0, 0);
        }

        @include breakpoint(one) {
            transform: translate3d($module-width * (-1), 0, 0);
        }
    }
}

// Modal sub styling
@import 'modal/cart';
@import 'modal/wishlist';
@import 'modal/account';
@import 'modal/filter';
@import 'modal/contact';
