// Loading ajax
.ajax-loading-layer {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(255, 255, 255, 0.8);
    pointer-events: none;
    z-index: map-get($z-index, loading-layer);
    transition: opacity 0.4s linear 0s,
                visibility 0s linear 0.4s;
}

body[aria-busy="true"] .ajax-loading-layer {
    transition: opacity 0.4s linear 0s;
    visibility: visible;
    opacity: 1;
    pointer-events: all;
}