.category-topics {
    width: 5 * $module-width;
    position: relative;
    margin: 0 auto $margin--l auto;
    text-align: left;

    h2 {
        @include typo-zurich-bd;
        @include typo-zurich--2;
        padding: 0 $padding--xxs;
        margin-bottom: $margin--s;
    }

    .category-topics__list {
        @include pseudo-list;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .category-topics__list__item {
        width: $module-width;
        height: $module-width;
        padding: $padding--xxs;
    }

    @include breakpoint(four) {
        width: 4 * $module-width;

        .category-topics__list__item {
            &:nth-child(5) {
                display: none;
            }
        }
    }

    @include breakpoint(three) {
        width: 3 * $module-width;

        .category-topics__list__item {
            &:nth-child(4),
            &:nth-child(5) {
                display: none;
            }
        }
    }

    @include breakpoint(three) {
        width: 3 * $module-width;

        .category-topics__list__item {
            &:nth-child(4),
            &:nth-child(5) {
                display: none;
            }
        }
    }

    @include breakpoint(two) {
        width: 2 * $module-width;

        .category-topics__list__item {
            &:nth-child(5) {
                display: none;
            }
        }
    }

    @include breakpoint(one) {
        width: 1 * $module-width;

        .category-topics__list__item {
            &:nth-child(4),
            &:nth-child(5) {
                display: none;
            }
        }
    }
}
