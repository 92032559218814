// Variables

// Text colors / Main
$text-color--default: #000;
$text-color--light: #fff;
$text-color--black-1: #111111;
$text-color--grey-light-1: #a9a9a9;
$text-color--grey-light-2: #646464;
$text-color--button: #000;
$text-color--button--hover: rgb(150, 150, 150);
$text-color--error-1: #be2020;
$text-color--promotion: #9EB8D5;
$text-color--account-balance: #A0A0A0;
$text-color--service-part: $text-color--account-balance;

// Background colors / Main
$back-color--default: #fff;
$back-color--light-1: #f0f0f0;
$back-color--light-2: #ebebeb;
$back-color--dark-1: #7B7B7B;
$back-color--input: #e1e1e1;
$back-color--input-light-1: #f0f0f0;
$back-color--input-light-2: #ebebeb;
$back-color--input-light-3: #fafafa;
$back-color--input-error: #f0d7d9;
$back-color--footer: #f0f0f0;
$back-color--modal: #f0f0f0;
$back-color--detail-image: #f7f7f7;
$back-color--image-unloaded: rgb(250, 250, 253);
$back-color--out-of-stock: rgba(255, 255, 255, 0.8);

// Border colors
$border-color--default: #000;
$border-color--button: #000;
$border-color--light-1: #999999;
$border-color--light-2: #b5b5b5;
$border-color--light-3: #cfcfcf;
$border-color--light-4: #e9e9e9;
$border-color--light-5: #7f7f7f; // Sub Articles active state
$border-color--button--hover: rgb(150, 150, 150);

// Icon colors
$icon-color--core: #bf9257;
$icon-color--pr: #e58a98;
$icon-color--asia: #dd3c4b;
$icon-color--retail-stock: #78b72b;
$icon-color--consumer-stock: #a600a6;
$icon-color--promotion: #9EB8D5;
$icon-color--service-credit: #A0A0A0;

// Module sizing
$module-width: 24rem;
$module-height: 16rem;

// Box Padding
$padding--default: 6px;
$padding--large: 12px;
$padding--xxxs: 0.25rem;
$padding--xxs: 0.5rem;
$padding--xs: 1rem;
$padding--s: 2rem;
$padding--m: 4rem;

// Margins
$margin--xxxs: 0.25rem;
$margin--xxs: 0.5rem;
$margin--xs: 1rem;
$margin--s: 2rem;
$margin--m: 4rem;
$margin--l: 8rem;
$margin--xl: 12rem;

// Component sizes
$header-height--desktop: 12rem;
$header-height--mobile: 4.5rem;
$input-size--small: 2.5rem;
$input-size--default: 3rem;
$input-size--large: 3.5rem;
$button-size--default: 3rem;
$button-size--large: 3.5rem;

// icon colors
$icon-color--default: $text-color--default;

// shadow

// Vertical rhythm
// --> see $grid-config

// Transition times // todo
$trans-time--xs: 0.125s;
$trans-time--s: 0.25s;
$trans-time--m: 0.4s;
$trans-time--l: 0.8s;
$trans-time--modal: 0.4s;
$trans-time--overlay: 0.5s;

// Transition funcs // todo
$trans-func--default: cubic-bezier(0.1, 0.9, 0.3, 1);
$trans-func--easing: ease-in-out;
$trans-func--linear: linear;
$trans-func--modal: ease-in-out;
$trans-func--overlay: ease-in-out;

// z-indexing
$z-index: (
    some-z: 1,
    header: 5,
    mobile-menu: 2001,
    modal: 2002,
    select-2: 3001,
    info-layer-overlay: 3501,
    info-layer: 3501,
    global-tooltip: 4001,
    loading-layer: 5001,
    development-grid: 10000,
);

