.page--search {
    .search-results-headline-container {
        display: block;
        margin: $margin--l 0 $margin--xs 0;
        text-align: left;

        h1 {
            @include typo-zurich-bd;
            @include typo-zurich--3;

            @include breakpoint(mobile) {
                @include typo-zurich--2;
            }
        }
    }

    .search-results-container {
        margin-bottom: $margin--l;
    }
}
