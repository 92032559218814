.page--login {
    height: 100vh;

    .main-login-container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .main-login-dialog {
        width: 2 * $module-width;;
        border: solid 1px $border-color--default;
        padding: $padding--s 0;

        &[data-mode="lostpw"] {
            .login-mode-container {
                transform: translate3d(-40rem, 0, 0);
            }

            .login-mode-column--login {
                opacity: 0;
            }

            .login-mode-column--lostpw {
                opacity: 1;
            }

            .login-mode-column--reset {
                opacity: 0;
            }
        }

        &[data-mode="reset"] {
            .login-mode-container {
                transform: translate3d(-80rem, 0, 0);
            }

            .login-mode-column--login {
                opacity: 0;
            }

            .login-mode-column--lostpw {
                opacity: 0;
            }

            .login-mode-column--reset {
                opacity: 1;
            }
        }
    }

    .main-login-dialog-header,
    .main-login-dialog-body,
    .main-login-dialog-footer {
        display: block;
        padding: $padding--s $padding--m;
        position: relative;
    }

    form {
        display: block;
        width: calc(100% - 2px);
    }

    .main-login-dialog-header {
        img {
            width: 20rem;
        }
    }

    .main-login-dialog-footer {
        display: block;
    }

    .login-mode-wrapper {
        display: block;
        overflow: hidden;
        width: 100%;
    }

    .login-mode-container {
        width: 120rem;
        display: flex;
        transition: transform $trans-time--m $trans-func--default;
        transform: translate3d(0, 0, 0);
    }

    .login-mode-column {
        width: 40rem;
        display: block;
        transition: opacity $trans-time--m $trans-func--easing;
        padding: 0 1px;
    }

    .login-mode-column--login {
        opacity: 1;
    }

    .login-mode-column--lostpw {
        opacity: 0;
    }

    .login-mode-column--reset {
        opacity: 0;
    }

    .main-login-dialog-footer {
        display: block;
        padding-top: 0;

        .login-mode-column {
            display: flex;
            justify-content: flex-end;
        }
    }

    @include breakpoint(one) {
        .main-login-dialog {
            width: $module-width;
            padding: $padding--xs 0;
        }

        .main-login-dialog-header,
        .main-login-dialog-body,
        .main-login-dialog-footer {
            padding: $padding--xs $padding--xs;
        }

        .login-mode-container {
            width: 66rem;
        }

        .login-mode-column {
            width: 22rem;
        }

        .main-login-dialog {
            &[data-mode="lostpw"] {
                .login-mode-container {
                    transform: translate3d(-22rem, 0, 0);
                }
            }

            &[data-mode="reset"] {
                .login-mode-container {
                    transform: translate3d(-22rem, 0, 0);
                }
            }
        }

        .main-login-dialog-footer {
            .login-mode-column {
                display: block;

                .btn {
                    margin-top: $margin--xxs;
                }
            }
        }
    }
}

.tooltip--login {
    text-align: left;
    top: -4rem;
    left: auto;
    right: -4rem;
    transform: translate3d(100%, 0, 0);

    @include breakpoint(fourfive) {
        &:after {
            top: 8.5rem;
        }

        &.has-password-focussed:after {
            top: 15rem;
        }
    }

    @include breakpoint(four) {
        width: $module-width;
    }

    @include breakpoint(onethree) {
        width: 40rem;
        top: 0;
        left: 0;
        right: auto;
        transform: translate3d(4rem, -100%, 0);

        &:after {
            margin-top: -1rem;
            top: auto;
            bottom: -2rem;
            left: auto;
            right: 4rem;
            transform: rotate(315deg);
        }

        &.has-password-focussed {
            top: 8rem;
        }
    }

    @include breakpoint(one) {
        display: none;
        //width: 22rem;
        //transform: translate3d(1rem, -100%, 0)
    }
}
