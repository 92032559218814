.product-icon-list {
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;

    .product-icon {
        display: inline-block;
        line-height: 1;

        a {
            display: flex;
            align-items: center;
        }

        .svg-ico {
            display: block;
            width: 14px; //1rem;
            height: 14px; //1rem;
            overflow: hidden;
            line-height: 1;
        }

        &:not(:last-child) {
            margin-right: $margin--xxxs;
        }

        &.product-icon--retail {
            .svg-ico {
                fill: $icon-color--retail-stock;
            }
        }

        &.product-icon--consumer {
            .svg-ico {
                fill: $icon-color--consumer-stock;
            }
        }

        &.product-icon--promotion {
            .svg-ico {
                fill: $icon-color--promotion;
            }
        }

        &.product-icon--service-credit {
            .svg-ico {
                fill: $icon-color--service-credit;
            }
        }
    }

    .product-icon-label {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
        color: $text-color--default;

        &.product-icon-label--service-credit {
            color: $text-color--service-part;
        }
    }

    // Listing overview
    &.product-icon-list--listing {
        position: relative;
        justify-content: center;
    }

    // Detail page
    &.product-icon-list--detail {
        margin-left: $margin--xs;

        .product-icon {
            .svg-ico {
                width: 2rem;
                height: 2rem;
            }

            &:not(:last-child) {
                margin-right: $margin--xxs;
            }
        }

        @include breakpoint(one) {
            .product-icon {
                .svg-ico {
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }
        }
    }

    // Detail page / Stock
    &.product-icon-list--detail-stock {
        margin-bottom: $margin--xs;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: $margin--xs $margin--s;

        .product-icon {
            display: flex;
            align-items: center;

            .svg-ico {
                margin-right: $margin--xxs;
            }
        }

        .product-icon--info {
            flex: 1 1 auto;
            display: flex;
            justify-content: flex-end;
        }

        .product-icon-label {
            line-height: 1;
        }

        @include breakpoint(two) {
            .product-icon--info {
                justify-content: flex-start;
            }
        }

        @include breakpoint(one) {
            position: relative;
            display: block;
            padding-right: $padding--s;
            margin-bottom: $margin--s;

            .product-icon {
                margin-right: 0;
                margin-bottom: $margin--xxs;
            }

            .product-icon--info {
                justify-content: flex-start;
            }
        }
    }

    // Detail Page / Sub Articles / Stock
    &.product-icon-list--detail-sub {
        position: absolute;
        top: 2px;
        right: 2px;
        height: 7px;
        display: flex;

        .product-icon {
            height: 7px;
            line-height: 1;

            .svg-ico {
                width: 7px;
                height: 7px;
            }
        }
    }

    // Cart / Wishlist
    &.product-icon-list--cart,
    &.product-icon-list--wishlist {
        display: flex;
        margin: $margin--xxxs 0;

        .product-icon {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &:not(:last-child) {
                margin-right: $margin--xxs;
            }
        }

        .svg-ico + .product-icon-label {
            margin-left: $margin--xxs;
            line-height: 1;

            @include breakpoint(mobile) {
                @include truncate(12.5rem);
            }
        }
    }
}

.product-icon--detail-title {
    margin-left: $margin--xxxs;
}