button,
.btn {
    @include typo-zurich;
    @include typo-zurich--0;
    display: block;
    height: $button-size--default;
    line-height: $button-size--default;
    cursor: pointer;
    border: none;
    text-align: center;
    outline: none;
    text-transform: none;
    padding: 0;
    color: $text-color--button;
    background: none;
    transition: color $trans-time--m $trans-func--default,
                opacity $trans-time--m $trans-func--default,
                border $trans-time--m $trans-func--default,
                background $trans-time--m $trans-func--default;

    &:not(.btn--icon):not([disabled]):hover {
        color: $text-color--button--hover;
    }

    &.btn--primary {
        @include typo-zurich-bd;
        @include typo-zurich--0;
        height: $button-size--large;
        line-height: $button-size--large;
        border: solid 1px $border-color--button;
        width: 100%;

        &:hover:not([disabled]) {
            color: $text-color--button--hover;
            border-color: $border-color--button--hover;
        }
    }

    &.btn--secondary {
        @include typo-zurich;
        @include typo-zurich--0;
        height: $button-size--large;
        line-height: $button-size--large;
        border-top: solid 1px $border-color--button;
        border-bottom: solid 1px $border-color--button;
        width: 100%;
        text-align: left;

        &:hover {
            color: $text-color--button--hover;
            border-color: $border-color--button--hover;
        }
    }

    &.btn--icon {
        width: auto;
        height: auto;
        line-height: 1;

        &.btn--icon-xs {
            .svg-ico {
                width: 1rem;
                height: 1rem;
            }
        }

        &.btn--icon-s {
            .svg-ico {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        &.btn--icon-m {
            .svg-ico {
                width: 2rem;
                height: 2rem;
            }
        }
    }

    &.btn-outline,
    &.btn--outline {
        border: solid 1px #000;
    }

    &.btn-half-outline {
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
    }

    &.btn-underlined {
        text-decoration: underline;
    }

    &.btn-centered,
    &.btn--centered {
        text-align: center;
        padding-right: 0;
    }

    &.btn-clean {
        border: none;
    }

    &.btn-auto {
        width: auto;
        padding-left: 2em;
        padding-right: 2em;
    }

    &.btn-full,
    &.btn--full {
        width: 100%;
    }

    &.btn-ico,
    &.btn--ico {
        width: auto;
        height: auto;
        line-height: 1;
        border: none;
        text-align: center;
        padding: 0;
    }

    &.btn--clean {
        border: none;
        padding: 0;
        width: auto;
        background: none;
    }

    &.btn--large {
        height: 4rem;
        line-height: 4rem;
    }

    &.btn--inverted,
    &.btn--primary.btn--inverted {
        background-color: #000;
        color: #fff;
        border-color: #000;

        &:hover {
            background-color: rgb(50, 50, 50);
            border-color: rgb(50, 50, 50);
            color: #fff;
        }
    }

    &.btn--link {
        font-size: 1.1rem;
        line-height: 1.1;
        display: inline-block;
        height: auto;
        width: auto;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid 1px #000;

        &:hover {
            border-color: rgb(150, 150, 150);
        }
    }

    &.btn--clean {
        font-size: 1.1rem;
        line-height: 1.1;
        display: inline-block;
        height: auto;
        width: auto;
        border: none;
    }

    &.btn--dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: none;
        text-indent: -999rem;
        overflow: hidden;
        background-color: #b3b3b3;
        border-radius: 100%;

        &.is-active {
            background-color: #000;
        }
    }
    
    &.btn--default-cursor {
        cursor: default;
    }

    &[disabled] {
        color: $text-color--button--hover;
        border-color: $border-color--button--hover;

        &:not(.btn--default-cursor) {
            cursor: not-allowed;
        }
    }
}
