.modal--cart,
.modal--wishlist,
.checkout-sidebar-cart {
    ul {
        @include pseudo-list;
    }

    // Listing
    .cart-listing,
    .wishlist-listing {
        border-top: solid 1px $border-color--default;
        margin-bottom: 2rem;

        .cart-listing-item,
        .wishlist-listing-item {
            text-align: left;
            position: relative;
            display: block;
            padding: $padding--xs 0 0 0;

            &:last-child {
                border-bottom: none;
            }

            &.cart-product,
            &.wishlist-product {
                padding-top: 0;
            }

            &.cart-summary {
                border-top: solid 1px $border-color--default;
                padding-top: $padding--s;
                padding-bottom: $padding--xs;

                &.cart-summary--empty {
                    padding: 0;
                }
            }

            &.cart-delivery-preference {
                margin-top: $margin--m;
                border-top: solid 1px $border-color--default;
            }

            &.cart-shipping-options {
                border-top: solid 1px $border-color--default;
            }

            &.is-promotion {
                .product-order-type-quantity-value {
                    @include typo-zurich--1;
                    padding-left: 2rem;
                }
            }

            &.is-restricted {
                .quantity-label,
                .product-order-type-quantity-value {
                    @include typo-zurich--1;
                    padding-left: 3rem;
                }
            }
        }

        &.cart-listing--modal,
        &.wishlist-listing--modal {
            .cart-listing-item,
            .wishlist-listing-item {
                &:not(:first-child):not(.cart-summary) {
                    border-top: solid 1px $border-color--light-1;
                }
            }

            .cart-summary--empty ~ .cart-listing-item.cart-voucher {
                border-top: none;
                padding: 0;
            }
        }

        &:not(.cart-listing--modal):not(.wishlist-listing--modal) {
            .cart-product,
            .wishlist-product {
                border-bottom: solid 1px $border-color--light-1;
            }
        }
    }

    .wishlist-listing {
        border-bottom: solid 1px $border-color--default;
    }

    .box-link {
        overflow: hidden;

        picture {
            display: block;
            background-color: $back-color--modal;

            img {
                mix-blend-mode: multiply;
            }
        }
    }

    // Product description
    .product-details {
        opacity: 1;
        display: flex;
        align-items: flex-start;
        width: 100%;

        li {
            display: inline-block;
        }

        span {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
        }

        @include breakpoint(one) {
            flex-wrap: wrap;
        }
    }

    .product-details-info {
        transition: opacity $trans-time--m $trans-func--default;
        padding-top: 1rem;
        flex: 1 1 auto;

        ul {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        .ie.no-edge & {
            flex: 1 1 0;
            padding-left: 1rem;
        }

        .edge & {
            padding-left: 1rem;
        }
    }

    .product-details-image {
        width: 10rem;

        .image-wrapper {
            display: inline-block;
            overflow: hidden;
            width: 10rem;
            max-height: 6rem;
        }

        @include breakpoint(mobile) {
            width: 100%;

            .image-wrapper {
                width: 100%;
                max-height: none;
                height: 14rem;
            }
        }

        @include breakpoint(two) {
            .panel--sidebar-cart & {
                width: 10rem;

                .image-wrapper {
                    width: 10rem;
                    height: auto;
                }
            }
        }
    }

    .product-details-name {
        text-align: left;
        flex: 1 1 auto;

        a {
            display: flex;
        }

        span {
            @include typo-zurich-bd;
            text-transform: none;

            @include breakpoint(desktop) {
                display: inline-block;
                @include truncate(20rem);
            }
        }
    }

    .product-details-quantity {
        text-align: left;
        width: 6rem;
    }

    .product-details-price {
        position: relative;
        width: 8rem;
        text-align: right;

        span {
            display: block;

            &.is-promotion {
                color: $text-color--promotion;
            }

            &.is-floating {
                position: absolute;
                top: 1.5rem;
                right: 0;
            }

            &.is-paid-via-service-account {
                color: $text-color--account-balance;
            }
        }

        @include breakpoint(one) {
            width: 6rem;
        }
    }

    .product-details-description {
        flex: 1 1 auto;
        min-width: 24rem;
        padding-bottom: $padding--xs;

        @include breakpoint(mobile) {
            padding-top: 0.25rem;
        }

        @include breakpoint(one) {
            min-width: auto;
            width: 14rem;
        }
    }

    .product-details-promotion {
        width: 6rem;
        text-align: right;

        span {
            display: block;
            color: $text-color--promotion;
        }

        @include breakpoint(one) {
            width: 4rem;
        }
    }

    .product-details-paid-via-account {
        width: 6rem;
        text-align: right;
        padding-bottom: $padding--xs;

        span {
            display: block;
            color: $text-color--account-balance;
        }
    }

    // Quantity
    .form-sidebar-cart-quantity {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .quantity-label {
        display: inline-block;
        line-height: 1.5rem;
        height: 1.5rem;
    }

    .btn--quantity {
        display: inline-block;
        height: 1.5rem;
        padding-top: 0.25rem;

        &:not([disabled]) {
            cursor: pointer;
        }

        &[disabled] {
            cursor: not-allowed;
        }

        &.btn--quantity-decrease {
            margin-right: 0.75rem;
        }

        &.btn--quantity-increase {
            margin-left: 0.75rem;
        }

        .svg-ico {
            width: 1rem;
            height: 1rem;
        }
    }

    // Add adjustments for service parts
    @mixin _service-part-adjustments {
        .product-details-name {
            order: 1;
        }

        .product-details-price {
            order: 3;
            text-align: right;
            margin-left: auto;
            width: 100%;
        }

        .product-details-quantity {
            position: relative;
            margin-left: auto;
            width: auto;
            order: 2;
            z-index: 2;
        }

        .product-details-description {
            order: 4;
            margin-top: -1.5rem;
        }

        .product-details-paid-via-account {
            order: 5;
        }
    }

    // Service part inside modal cart
    .modal-content .is-service-part {
        @include breakpoint(mobile) {
            @include _service-part-adjustments;
        }

        @include breakpoint(one) {
            .product-details-name {
                span {
                    @include truncate(12.5rem);
                    display: inline-block;
                }
            }

            .product-details-description {
                width: 12.5rem;
            }
        }

        @include breakpoint(two) {
            .product-details-name {
                span {
                    @include truncate(14rem);
                    display: inline-block;
                }
            }
        }
    }

    // Service part inside checkout sidebar basket
    .is-service-part {
        .panel--sidebar-cart & {
            @include breakpoint(one) {
                @include _service-part-adjustments;

                .product-details-name {
                    span {
                        @include truncate(13.5rem);
                        display: inline-block;
                    }
                }
            }

            @include breakpoint(two) {
                .product-details-name {
                    span {
                        @include truncate(20rem);
                        display: inline-block;
                    }
                }
            }
        }
    }

    // Order type
    .product-order-type-list {
        @include pseudo-list;
        display: block;
        width: 100%;
        border-top: solid 1px $border-color--light-3;
    }

    .product-order-type {
        display: block;
        width: 100%;
        padding: $padding--xs 0;

        .toggle-panel-head {
            transition: padding $trans-time--m $trans-func--easing;

            a[role="button"] {
                display: block;
            }
        }

        .toggle-panel-body {
            padding-bottom: 0;
        }

        .product-order-section-select,
        .toggle-panel-head-cart-group {
            display: flex;
        }

        .product-order-section {
            &:not(:last-child) {
                margin-bottom: $margin--xs;
            }
        }

        .product-order-type-label {
            @include typo-zurich;
            @include typo-zurich--1;
            flex: 1 1 auto;
            text-transform: none;
        }

        .product-order-type-quantity-select {
            display: inline-block;
            width: 6rem;
            margin-right: 8rem;
            text-align: left;

            select {
                width: 100%;
                background: none;
                padding: 0;
                height: auto;
                line-height: 1;
            }

            @include breakpoint(one) {
                margin-right: 6rem;
            }
        }

        .product-message-list {
            @include pseudo-list;
            display: block;
            width: 100%;
            max-width: 100%;

            &.product-message-list--restricted {
                .product-message {
                    padding: $padding--xs;
                    border: solid 1px $border-color--light-3;
                }
            }
        }

        .product-message {
            display: inline-block;
            width: 100%;

            textarea {
                width: 100%;
                height: 5rem;
                resize: none;
            }

            &:not(:last-child) {
                margin-bottom: $margin--xxs;
            }

            .product-message-value {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
                font-style: italic;

                &.product-message-value--na {
                    color: $text-color--grey-light-2;
                }
            }
        }

        .product-order-section-select {
            margin-bottom: $margin--xs;
        }

        .toggle-panel--cart {
            &[aria-expanded="false"] {
                .toggle-panel-head--stock {
                    padding-bottom: $padding--xxs;
                }

                .toggle-panel-head--patient {
                    padding-bottom: 0;
                }
            }

            &[aria-expanded="true"] {
                .toggle-panel-head--stock {
                    padding-bottom: $padding--xxs;
                }

                .toggle-panel-head--patient {
                    padding-bottom: $padding--xxs;
                }

                .toggle-panel-head--stock.has-quantity,
                .toggle-panel-head--patient.has-quantity {
                    padding-bottom: $padding--xs;
                }

                .toggle-panel-body--stock.has-quantity {
                    padding-bottom: $padding--xs;
                }
            }
        }
    }

    // Delivery prefernce / Shipping options
    .cart-delivery-preference,
    .cart-shipping-options {
        h3 {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
        }

        .cart-delivery-preference-hint,
        .cart-shipping-options-hint {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            margin-bottom: $margin--s;
        }

        .pikaday-group {
            width: 16rem;
            margin-left: 2rem;
        }

        input[type="date"],
        .pikaday {
            display: inline-block;
            width: 100%;
            height: 2rem;
            line-height: 2rem;
        }

        input[type="checkbox"] + label {
            text-transform: none;
        }

        .delivery-preference-list {
            @include pseudo-list;
            display: block;
            width: 100%;
        }

        .delivery-preference {
            display: block;
            width: 100%;

            &:not(:last-child) {
                margin-bottom: $margin--xxs;
            }
        }

        .cart-delivery-preference-intro {
            margin-bottom: $margin--s;
        }

        .toggle-panel {
            .toggle-panel-head {
                padding-bottom: $padding--xs;
            }

            &[aria-expanded="true"] {
                .toggle-panel-body {
                    padding-bottom: $padding--xs;
                }
            }
        }

        .shipping-options-list {
            display: flex;
            flex-direction: column;
        }

        .shipping-options-list__entry {
            display: block;

            &:not(:last-child) {
                margin-bottom: $margin--xxs;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }

        @include breakpoint(one) {
            .pikaday-group {
                margin-left: 0;
                margin-top: $margin--xxxs;
            }
        }

        .direct-delivery-hint {
            display: none;
            margin-bottom: $margin--xs;

            p {
                font-style: italic;
            }
        }

        input[name="orderDeliveryPreference"]:checked ~ .direct-delivery-hint {
            display: block;
        }
    }


    // Definition list
    dl {
        @include clearfix;
        margin: 0;

        dt {
            display: inline-block;
            float: left;
            margin: 0;
        }

        dd {
            display: inline-block;
            float: right;
            margin: 0;
        }

        p {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            margin-bottom: 0;

            &.is-primary {
                @include typo-zurich-bd;
                @include typo-zurich--1-1;
                text-transform: none;
            }
        }

        &:not(:last-child) {
            margin-bottom: $margin--xxs;
        }

        &.cart-summary-definition--promotion-discount {
            color: $text-color--promotion;
        }

        &.cart-summary-definition--service-bonus,
        &.cart-summary-definition--new-service-balance {
            color: $text-color--account-balance;
        }

        &.cart-summary-definition--total {
            //margin-top: $margin--xs;
        }

        .cart-summary-vat,
        .cart-summary-shipping {
            display: inline-block;

            &:first-child {
                margin-right: $margin--xs;
            }
        }
    }

    .cart-hint-promotion-articles {
        margin-top: $margin--xs;

        p {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            color: $text-color--promotion;
        }
    }

    // Voucher input
    .cart-voucher-container {
        margin-top: $margin--xxs;
    }

    .input-group--voucher {
        gap: 1rem;
        align-items: center;

        .input-group-item {
            width: auto;

            &:first-child {
                flex: 1 1 auto;
            }
        }
    }
}
