.category-introduction {
    display: block;
    margin-top: $margin--s;
    margin-bottom: $margin--l;

    .category-introduction-content {
        max-width: 3 * $module-width;
        margin: $margin--m auto 0 auto;
    }

    h1 {
        @include typo-letter;
        @include typo-letter--2;
        margin-bottom: 1rem;
    }

    p {
        @include typo-zurich-bd;
        @include typo-zurich--2;
    }
}