@media print {
    @page {
        size: A4;
        margin: 15mm 10mm;
    }

    .outer-wrapper {
        padding-top: 0 !important;
    }

    .header--main {
        position: relative !important;
        top: auto;
        left: auto;
    }

    .footer--main,
    .nav--header-mobile {
        display: none !important;
    }
}