.teaser {
    .teaser-link {
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;

        &:hover {
            .media-box {
                opacity: 0.5;
            }
        }
    }

    .media-box {
        transition: opacity $trans-time--m $trans-func--easing;
        opacity: 1;
    }

    .teaser-text {
        position: absolute;
        left: 0;
        bottom: 1.5rem;
        width: 100%;
        text-align: center;
        color: $text-color--light;
        padding: 0 1rem;

        h3 {
            @include typo-zurich;
            @include typo-zurich--1;
        }

        &.teaser-text--inverted {
            color: $text-color--default;
        }
    }
}
