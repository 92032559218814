.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
    text-align: left;
    outline: none;
    width: 100% !important;

    @include typo-letter;
    @include typo-letter--0;

    @import 'single';
    @import 'multiple';

    .selection {
        outline: none;
    }

    .select2-selection {
        outline: none;
    }

    .select2-selection__arrow {
        width: $input-size--default;
        height: $input-size--default;
        position: absolute;
        top: 0;
        right: 0;

        background: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDY0IDY0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA2NCA2NDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIHBvaW50cz0iMzYuNyw0NS40IDM2LjcsNDUuNCA2NCwxOC4xIDU4LjcsMTIuOCAzMS40LDQwLjEgNS4zLDE0IDAsMTkuMyAzMS40LDUwLjcgIi8+PC9zdmc+) transparent no-repeat 50% 50%;
        background-size: 1rem 1rem;
        transition: transform $trans-time--m $trans-func--default;
        transform: scaleY(1);

        b {
            display: none;
        }
    }

    &.select2-container--open {
        .select2-selection__arrow {
            transform: scaleY(-1);
        }
    }

    &.select2-container--clean {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
    }

    &.select2-container--quantity {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;

        .select2-selection__arrow {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &.select2-container--disabled {
        color: $text-color--grey-light-1;
    }
}

@import 'dropdown';

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;

    // styles required for IE to work

    background-color: #fff;
    filter: alpha(opacity=0);
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}
