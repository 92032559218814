.page--customer {
    .customer-overview {
        text-align: left;
        margin-top: $margin--m;
        margin-bottom: $margin--l;

        h1 {
            margin-bottom: $margin--m;
        }

        .panel--service-account {
            dl {
                display: flex;
                justify-content: space-between;
                margin: 0;
            }

            dt,
            dd {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
            }
        }

        @include breakpoint(desktop) {
            .panel--team {
                p {
                    display: inline-block;

                    &:last-child:before {
                        content: ' / ';
                    }
                }
            }
        }
    }

    .customer-settings {
        margin-top: $margin--s;
    }
}

// Select shipping address
.select-shipping-address-list {
    @include pseudo-list;

    .select-shipping-address-item {
        display: inline-block;
        width: calc(50% - #{$margin--xs});
        padding: $padding--xs;
        cursor: pointer;
        border: solid 1px $border-color--light-4;
        transition: border $trans-time--m $trans-func--easing;

        &[aria-selected="true"] {
            border-color: $border-color--default;
        }
    }

    &:hover {
        .select-shipping-address-item {
            &[aria-selected="true"]:not(:hover) {
                border-color: $border-color--light-4;
            }

            &:hover {
                border-color: $border-color--default;
            }
        }
    }

    @include breakpoint(desktop) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .select-shipping-address-item {
            margin-top: $margin--s;

            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 0;
            }
        }
    }

    @include breakpoint(mobile) {
        display: block;
        width: 100%;

        .select-shipping-address-item {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: $margin--s;
            }
        }
    }
}

// Service account
.service-account {
    text-align: left;
    margin-top: $margin--s;

    .panel-group {
        margin-top: $margin--s;
    }

    .service-account__balance-summary {
        .panel__sticky-wrapper {
            @include breakpoint(desktop) {
                position: relative;
                position: sticky;
                top: 5.5rem;
            }
        }

        .panel-action {
            margin-top: $margin--l;

            @include breakpoint(mobile) {
                display: none;
            }
        }

        dl {
            display: flex;
            justify-content: space-between;
            margin: 0;
        }

        dt,
        dd {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;

            &.contains-icon {
                display: flex;
                align-items: center;

                .svg-ico {
                    width: 1.1rem;
                    height: 1.1rem;
                    margin-right: $margin--xxs;
                    line-height: 1;
                }
            }
        }

        .service-account__balance-summary-initial {
            padding: $padding--xs 0;
            margin-top: $margin--s;
            border-top: solid 1px $border-color--default;
            border-bottom: solid 1px $border-color--default;
        }

        .service-account__balance-summary-current {
            margin-top: $margin--xs;

            dt,
            dd {
                @include typo-zurich-bd;
                text-transform: none;
            }
        }
    }

    .service-account__turnovers {
        .panel-action {
            margin-top: $margin--m;

            @include breakpoint(desktop) {
                display: none;
            }
        }
    }

    // Turnover list
    .service-account__turnover-list {
        @include pseudo-list;
    }

    .service-account__turnover-group {
        @include typo-zurich-bd;
        @include typo-zurich--1;
        text-transform: none;
        display: block;

        &:not(:first-child) {
            margin-top: $margin--s;
        }
    }

    .service-account__turnover-entry {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
        display: flex;
        margin-top: $margin--xxxs;

        .service-account__turnover-entry-description {
            flex: 1 1 auto;
            text-transform: uppercase;
        }

        .service-account__turnover-entry-amount {
            margin-left: 2rem;
            white-space: nowrap;
        }
    }

    .info-layer & {
        width: 100%;
        margin: 0;

        .panel {
            width: calc(50% - 24px);

            @include breakpoint(onethree) {
                width: 100%;
            }
        }

        .panel-action {
            display: none;
        }
    }
}