//  // Animations
//
//  // scss-lint:disable EmptyLineBetweenBlocks
//
//  @include keyframes(fade-in) {
//      0% {
//          opacity: 0;
//      }
//      100% {
//          opacity: 1;
//      }
//  }
//
//@include keyframes(load-pulse) {
//    0%, 100% {
//        transform: scale(0.7);
//    }
//    50% {
//        transform: scale(1);
//    }
//}
//
//  @include keyframes(equalizer-bar) {
//      0%, 100% {
//          height: 0;
//      }
//      50% {
//          height: 24px;
//      }
//  }
//
//  @include keyframes(layer-hold) {
//      0%, 100% {
//          opacity: 1;
//          transform: translate3d(0, 0, 0);
//      }
//  }
//
//  @include keyframes(layer-load) {
//      0% {
//          opacity: 1;
//          transform: translate3d(0, 100%, 0);
//      }
//      100% {
//          opacity: 1;
//          transform: translate3d(0, 0, 0);
//      }
//  }
//
//  @include keyframes(layer-fade) {
//      0% {
//          opacity: 1;
//          transform: translate3d(0, 0, 0);
//      }
//      98% {
//          opacity: 0;
//          transform: translate3d(0, 0, 0);
//      }
//      100% {
//          opacity: 0;
//          transform: translate3d(0, 100%, 0);
//      }
//  }
//
//  @include keyframes(wait-pulse) {
//      0%, 100% {
//          opacity: 0.3;
//      }
//      50% {
//          opacity: 1;
//      }
//  }
//
@include keyframes(wait-pulse-inverted) {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
}