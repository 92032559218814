.info-layer {
    transform: translate3d(0, 10%, 0);
    box-shadow: 0 0 5px 1px rgba(50, 50, 50, 0.15);
    display: inline-block;
    max-width: 90%;
    max-height: 80vh;
    background-color: $back-color--default;
    padding: 2rem 0;
    z-index: map-get($z-index, info-layer);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    pointer-events: none;
    position: relative;
    transition: opacity $trans-time--m $trans-func--default 0s,
                transform $trans-time--m $trans-func--default 0s,
                visibility 0s $trans-func--default $trans-time--m;

    .info-layer-close {
        position: absolute;
        top: 1.5rem;
        right: 2rem;
        cursor: pointer;
        width: auto;
        height: auto;
        line-height: 1;
        border: none;
        background: none;
        padding: 0;
        z-index: 10;

        .ico-myk {
            font-size: 1.5rem;
        }
    }

    .btn-close-layer {
        position: absolute;
        top: 2rem;
        right: 3rem;
        width: 2rem;
        height: 2rem;
        z-index: 1;

        .svg-ico {
            width: 100%;
            height: 100%;
        }
    }

    .info-layer-image {
        position: relative;
        padding-bottom: 66.66666%;
        margin-bottom: 2em;

        .info-layer-image-box {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
        }
    }

    .info-layer-wrapper {
        display: block;
        width: 3 * $module-width;
        max-height: 100%;

        // Make the wrapper scrollable
        > * {
            display: flex;
            flex-direction: column;
            max-height: 70vh;
            text-align: center;

            .info-layer-content,
            .cms-content-text {
                flex: 1 1 auto;
            }
        }
    }

    .info-layer-header,
    .cms-content-header {
        box-sizing: border-box;
        padding: 0 3rem;
        width: 100%;

        h1,
        h3 {
            @include typo-zurich;
            @include typo-zurich--1;
            text-align: left;
            padding-right: 4rem;
        }
    }

    .info-layer-content,
    .cms-content-text {
        overflow: auto;
        overflow-x: hidden;
        width: 100%;
        padding: 2rem 3rem;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior: contain;

        h1 {
            margin-bottom: $margin--m;
        }

        h2 {
            @include typo-zurich-bd;
            @include typo-zurich--2;
        }

        p {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            margin-bottom: 0;
        }
    }

    .info-layer-content--center {
        text-align: center;

        @include breakpoint(desktop) {
            padding-left: 6rem;
            padding-right: 6rem;
        }
    }

    .info-layer-content-inner {
        width: 100%;
    }

    .info-layer-controls {
        text-align: center;

        .btn {
            display: inline-block;
            width: 100%;
            max-width: 14em;
        }
    }

    .info-layer-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        margin: -16px 0 0 -16px;
        opacity: 1;
        pointer-events: none;
        background: $back-color--default url('../images/loader/myk-loader-default.gif') 50% 50% no-repeat;
        background-size: 32px 32px;
        transition: opacity $trans-time--m $trans-func--default;
    }

    .info-layer-action,
    .info-layer-pagination {
        padding: 2rem 3rem;
    }

    .info-layer-dont-show-again {
        padding: 2rem 3rem 1rem 2rem;
    }

    // Remove padding if dont show again comes directly after pagination
    .info-layer-pagination + .info-layer-dont-show-again {
        padding-top: 0;
    }

    .info-layer-action {
        display: flex;
        justify-content: center;

        .btn {
            width: 48%;
        }

        &.info-layer-action--both {
            justify-content: space-between;
        }
    }

    .info-layer-pagination {
        text-align: center;
        padding-top: $padding--xs;

        .info-layer-pagination-list {
            @include pseudo-list;
            display: inline-block;
        }

        .info-layer-pagination-item {
            display: inline-block;
            margin: 0 $margin--xxs;
        }
    }

    .info-layer-dont-show-again {
        position: relative;
        display: flex;
        justify-content: center;

        label {
            @include typo-zurich;
            @include typo-zurich--0;
            text-transform: none;
            line-height: 2rem;
            display: inline-block;
            width: auto;
            padding-right: 0;
        }
    }

    // Shades overlay
    &.info-layer--shades,
    &.info-layer--related-products {
        .product-listing {
            margin-bottom: 0;
        }

        .info-layer-content {
            padding-left: 0;
            padding-right: 0;
        }

        @include breakpoint(two) {
            .info-layer-wrapper {
                width: $module-width;
            }
        }
    }

    // Info layer that is filled async
    &.info-layer--async {
        .info-layer-wrapper {
            min-height: 12rem;
            max-width: 2 * $module-width;
            opacity: 0;
            transition: opacity $trans-time--m $trans-func--easing,
                        transform $trans-time--m $trans-func--default;
        }

        &.is-loaded {
            .info-layer-wrapper {
                opacity: 1;
            }
        }
    }

    // Gerneral CMS info layer
    &.info-layer--cms {
        max-height: none;

        .info-layer-wrapper {
            max-width: 3 * $module-width;
        }

        .cms-container {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }

        .cms-content-text {
            max-height: 70vh;
        }
    }

    // Info layer popup (e.g Claim Registration)
    &.info-layer--popup {
        .info-layer-content--columns {
            display: flex;
            padding: 0;
            margin-top: -2rem;
            margin-bottom: -2rem;
            max-height: 80vh;

            @include breakpoint(desktop) {
                overflow: hidden;
            }

            .info-layer-content__column {
                width: $module-width;
            }

            .info-layer-content__column--content {
                display: flex;
                flex-direction: column;
                padding: 2rem 3rem;

                @include breakpoint(desktop) {
                    overflow: auto;
                    overflow-x: hidden;
                    -webkit-overflow-scrolling: touch;
                    overscroll-behavior: contain;
                }

                h1 {
                    margin-bottom: 2rem;
                }

                p {
                    margin-bottom: 1rem;
                }
            }

            .info-layer-content__column__action {
                margin-top: $margin--s;
            }

            .info-layer-dont-show-again {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .btn-close-layer {
            right: 2rem;
        }

        @include breakpoint(one) {
            .info-layer-content--columns {
                flex-wrap: wrap;
            }

            .media-box--info-tower {
                @include aspect-ratio(1, 1);
            }
        }
    }

    // Use proper sizing for claim info headline
    .info-layer-content--claim {
        h1 {
            @include typo-zurich--3;
        }
    }

    // Wishlist share
    .info-layer-content--wishlist-share {
        padding-bottom: 0;

        .wishlist-share-form {
            margin-top: $margin--s;
        }

        .wishlist-share-response {
            @include typo-zurich;
            @include typo-zurich--2;
            text-transform: none;
        }
    }

    // Use proper styling for FYI popups
    .info-layer-content--fyi-service-usage,
    .info-layer-content--fyi-delivery {
        h1 {
            @include typo-zurich--6;
            margin-top: $margin--s;

            @include breakpoint(one) {
                @include typo-zurich--5;
                line-height: 1;
            }

            &.info-layer-content__headline--small {
                @include typo-zurich--5;

                @include breakpoint(two) {
                    @include typo-zurich--4;
                    line-height: 1;
                }

                @include breakpoint(one) {
                    @include typo-zurich--3;
                    line-height: 1;
                }
            }
        }

        p + p {
            margin-top: $margin--s;
        }

        strong {
            @include typo-zurich-bd;
            font-weight: normal;
            text-transform: none;
        }
    }

    // Use proper styling for FYI Service amount usage
    .info-layer-content--credit-boost {
        h1 {
            @include typo-zurich--4;
            margin-top: $margin--s;

            @include breakpoint(one) {
                @include typo-zurich--3;
                line-height: 1;
            }
        }

        p + p {
            margin-top: $margin--s;
        }

        strong {
            @include typo-zurich-bd;
            font-weight: normal;
            text-transform: none;
        }
    }

    .info-layer-content--id-number {
        text-align: left;

        p + p {
            margin-top: $margin--s;
        }

        mark {
            background: $back-color--light-2;
            border: solid 3px $back-color--light-2;
        }
    }

    // Media box
    .media-box--info {
        margin-bottom: $margin--s;
    }

    &[aria-busy="false"] {
        .info-layer-loader {
            opacity: 0;
        }
    }

    &[aria-hidden="false"],
    &.is-visible {
        transform: translate3d(-50%, 0, 0);
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition: opacity $trans-time--m $trans-func--default 0s,
                    transform $trans-time--m $trans-func--default 0s;
    }


    @include breakpoint(desktop) {
        .info-layer-action {
            padding-left: 6rem;
            padding-right: 6rem;
        }
    }

    @include breakpoint(three) {
        .info-layer-wrapper {
            width: 2 * $module-width;
        }
    }

    @include breakpoint(two) {
        .info-layer-wrapper {
            width: 1.5 * $module-width;
        }
    }

    @include breakpoint(one) {
        .info-layer-header,
        .info-layer-content,
        .cms-content-header,
        .cms-content-text {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .info-layer-wrapper {
            width: $module-width;
        }

        .btn-close-layer {
            right: 2rem;
        }
    }
}

// Info layer backdrop
.info-layer-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(250, 250, 253, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: map-get($z-index, info-layer-overlay);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity $trans-time--m $trans-func--default 0s,
                visibility 0s $trans-func--default $trans-time--m;

    &[aria-hidden="false"],
    &.is-visible {
        transition-property: opacity;
        transition-delay: 0s;
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        cursor: pointer;

        .info-layer {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            transform: translate3d(0, 0, 0);
            cursor: default;
            transition: opacity $trans-time--m $trans-func--default 0s,
                        transform $trans-time--m $trans-func--default 0s;
        }
    }
}
