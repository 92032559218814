.modal--contact {
    textarea {
        height: 16rem;
        resize: vertical;
        max-height: 32rem;
    }

    .message {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
        text-align: center;
        margin: 0;
    }

    .modal-action-contact {
        max-height: 8rem;
        opacity: 1;
        overflow: hidden;
        transition: max-height $trans-time--m $trans-func--easing,
                    opacity $trans-time--m $trans-func--easing;

        &[aria-hidden="true"] {
            opacity: 0;
            max-height: 0;
        }
    }

    .contact-hint {
        margin-top: $margin--m;

        p {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
        }
    }
}
