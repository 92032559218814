// Basic input styling
// -------------------
input,
textarea,
select  {
    @include typo-letter;
    @include typo-letter--0;
    text-transform: none;
    display: inline-block;
    background: $back-color--input;
    padding: 0 1rem;
    text-align: left;
    border: none;
    height: $input-size--default;
    line-height: $input-size--default;
    outline: none;
    border-radius: 0;
    transition: color $trans-time--m $trans-func--default,
                background-color $trans-time--m $trans-func--default;

    &[disabled],
    &[readonly] {
        cursor: not-allowed;
        color: $text-color--black-1;
    }

    &[type='search'] {
        -webkit-appearance: none;
    }

    &:focus {
        outline: none;
    }

    &.input--large {
        height: $input-size--large;
        line-height: $input-size--large;
    }

    &::placeholder {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    &.input--error,
    &.has-error {
        background-color: $back-color--input-error;
    }

    // Enlarge input fields
    &:not([type='checkbox']):not([type='radio']) {
        width: 100%;
    }

    &.input--border {
        background: none;
        border: solid 1px $border-color--default;
        height: 2.5rem;
        line-height: 2.5rem;
    }
}

// Select styling
select {
    &.select--clean,
    &.select2--language,
    &.select2--prices {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
        background: $back-color--default;
        padding-left: 0;
    }

    &.select2.has-error ~ .select2-container .select2-selection {
        background-color: $back-color--input-error;
    }
}

// Date styling
input[type='date'] {
    &::-webkit-clear-button,
    &::-webkit-inner-spin-button {
        display: none;
    }
}

// Textarea styling
textarea {
    line-height: 1.3;
    padding: 1rem;
}

.textarea--default {
    height: 10rem;
    resize: vertical;
}

// Labels
// ------
label {
    @include typo-zurich;
    @include typo-zurich--0;
    display: block;
    text-transform: uppercase;
    line-height: 2;
    text-align: left;

    a,
    .text-link {
        line-height: 1.2em;
    }

    &.label--required:after {
        content: '*';
    }

    &.label--optional-visible:after {
        @include typo-zurich--s2;
        display: inline-block;
        margin-left: 0.25rem;
        content: ' (Optional)';
        color: $text-color--grey-light-1;
    }

    &.label--underlined {
        text-decoration: underline;
    }

    &.label--toggleable {
        &[aria-hidden="true"] {
            display: none;
        }
    }

    .label-addition {
        display: inline-block;
    }

    .label-addition--lower {
        text-transform: none;
    }

    &.has-help {
        display: flex;
        align-items: center;

        span {
            display: inline-block;
        }

        &.label--required {
            &:after {
                content: '';
            }

            span:after {
                content: '*';
            }
        }

        .svg-ico {
            width: 1rem;
            height: 1rem;
            margin-left: $margin--xs;
        }
    }

    &.has-error {
        color: $text-color--error-1;
    }
}

// Form Rows
// ---------
.form-row {
    position: relative;
    opacity: 1;
    max-height: 64rem;
    margin-bottom: $margin--xs;

    &[aria-expanded] {
        overflow: hidden;
        transition: max-height $trans-time--m $trans-func--default,
                    opacity $trans-time--m $trans-func--default,
                    margin $trans-time--m $trans-func--default;
    }

    &[aria-expanded='false'] {
        max-height: 0;
        opacity: 0;
        margin-bottom: 0;
    }

    &.form-row-hint-small {
        @include typo-zurich;
        @include typo-zurich--0;
        text-transform: none;
        margin-top: 0.5rem;
    }

    &.form-row--message {
        margin-bottom: $margin--s;

        p {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            margin-bottom: 0;

            b,
            strong {
                @include typo-zurich-bd;
                text-transform: none;
            }
        }
    }

    &.form-row--headline {
        margin-bottom: $margin--xs;

        h3,
        h4 {
            margin-bottom: 0;
        }

        &:not(:first-child) {
            margin-top: $margin--s;
        }
    }

    &.form-row--hint {
        p {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            text-align: left;
            margin: 0;
            max-width: 1.5 * $module-width;
        }

        p + p {
            margin-top: $margin--xs;
        }

        .text-link {
            font-size: 1rem;
            line-height: 1.1;
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &.form-row--submit {
        margin-top: $margin--s;
    }

    .input-hint {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
        margin: $margin--xxs 0 0 0;
    }
}

// Form Groups
// -----------
.form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $margin--m;

    .form-group-column {
        display: inline-block;
        width: calc(#{$module-width} * 2.5 - #{$padding--large} * 2);

        &.form-group-column--flex {
            display: flex;
            flex-direction: column;
        }
    }

    .form-group-column-group {
        &:not(:last-child) {
            margin-bottom: $margin--m;
        }

        &.form-group-column-group--text {
            text-align: left;

            p {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
                margin: 0;
            }
        }
    }

    &.form-group--single-start {
        justify-content: flex-start;
    }

    &.form-group--single-end {
        justify-content: flex-end;
    }

    @include breakpoint(four) {
        .form-group-column {
            width: calc(#{$module-width} * 2 - #{$padding--large} * 2);
        }
    }

    @include breakpoint(three) {
        .form-group-column {
            width: calc(#{$module-width} * 1.5 - #{$padding--large} * 2);
        }
    }

    @include breakpoint(two) {
        .form-group-column {
            width: calc(#{$module-width} * 1 - #{$padding--large});
        }
    }

    @include breakpoint(one) {
        flex-wrap: wrap;

        .form-group-column {
            width: $module-width;

            &:not(:last-child) {
                margin-bottom: $margin--m;
            }
        }
    }
}

// Form Group Panel
// ----------------
.form-group-panel {
    position: relative;
    display: block;

    &[aria-expanded] {
        .form-row {
            overflow: hidden;
            transition: max-height $trans-time--m $trans-func--easing,
                        opacity $trans-time--m $trans-func--easing,
                        margin $trans-time--m $trans-func--easing;
        }
    }

    &[aria-expanded='false'] {
        .form-row:not(.form-row--toggle) {
            max-height: 0;
            opacity: 0;
            margin-bottom: 0;
        }
    }
}

.form-group-panel + .form-group-panel {
    margin-top: $margin--s;
}

// Fieldset related stuff
// ----------------------
fieldset {
    padding: 0;
    margin: 0;
    border: 0;

    legend {
        @include typo-letter;
        @include typo-letter--1;
        display: block;
        margin-bottom: $margin--s;
        text-align: left;
    }
}

// Input group
// -----------
.input-group {
    display: flex;
    justify-content: space-between;

    &.input-group--mobile-full {
        @include breakpoint(one) {
            flex-direction: column;

            .input-group-item {
                width: 100%;
            }

            .input-group-item + .input-group-item {
                margin-top: $margin--xs;
            }
        }
    }

    .input-group-item {
        display: inline-block;
        width: calc(50% - 1rem);
    }

    .input-group-item--zip {
        width: calc(30% - 1rem);
    }

    .input-group-item--city {
        width: calc(70% - 1rem);
    }

    @include breakpoint(mobile) {
        .input-group-item {
            width: calc(50% - 0.5rem);
        }

        .input-group-item--zip {
            width: calc(30% - 0.5rem);
        }

        .input-group-item--city {
            width: calc(70% - 0.5rem);
        }
    }

    @include breakpoint(one) {
        &.input-group--firstname-lastname {
            flex-wrap: wrap;
        }

        .input-group-item--firstname {
            width: 100%;
            margin-bottom: $margin--xs;
        }

        .input-group-item--lastname {
            width: 100%;
        }
    }
}

// Option group
// ------------
.option-group {
    @include pseudo-list;
    display: flex;

    .option-group-item,
    .option-group__item {
        position: relative;

        &:not(:last-child) {
            margin-right: $margin--s;
        }

        &.option-group__item--space-l:not(:first-child) {
            margin-top: $margin--xs;
        }
    }

    &.option-group--column {
        flex-direction: column;

        .option-group-item,
        .option-group__item {
            &:not(:last-child) {
                margin-right: 0;
                margin-bottom: $margin--xxs;
            }
        }
    }

    &.option-group--wrap {
        flex-wrap: wrap;
    }

    &.option-group--2-column {
        flex-wrap: wrap;

        .option-group-item,
        .option-group__item {
            width: 50%;
            margin-right: 0;
        }
    }
}

/* Checkboxes and Radiobuttons
   ========================================================================== */
input[type='checkbox'] + label,
input[type='radio'] + label {
    cursor: pointer;
    position: relative;
    display: inline-block;
    line-height: 2rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;

    &.inline {
        width: auto;
    }

    span {
        box-sizing: border-box;
        display: block;
        height: inherit;
        line-height: inherit;
    }

    &.label--lower {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;

        &:not(.label--multiline) {
            line-height: 2rem;
        }
    }

    &.label--multiline {
        padding-top: 0.25rem;
    }

    &.has-description {
        span,
        small {
            display: block;
        }

        small {
            @include typo-zurich;
            @include typo-zurich--0;
            text-transform: none;
        }

        p,
        li {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            margin: 0;
        }

        ul {
            list-style: disc;
            padding-left: 1.5rem;
        }

        .text-upper {
            text-transform: uppercase;
        }

    }
}

input[type='radio'],
input[type='checkbox'] {
    @include hide;
}

// Specific styles for checkboxes
input[type='radio'] + label:before,
input[type='checkbox'] + label:before,
input[type='radio'] + label:after,
input[type='checkbox'] + label:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    display: inline-block;
    transition: opacity $trans-time--s $trans-func--default,
                border $trans-time--s $trans-func--default;
}

// Before
input[type='radio'] + label:before,
input[type='checkbox'] + label:before {
    width: 1.4rem;
    height: 1.4rem;
    top: 0.3rem;
    left: 0;
    background: $back-color--input;
    opacity: 1;
}

// After
input[type='radio'] + label:after,
input[type='checkbox'] + label:after {
    width: 1rem;
    height: 1rem;
    top: 0.5rem;
    left: 0.2rem;
    background: #000;
    opacity: 0;
}

// Special styling for radiobuttons
input[type='radio']:not(.radio-checkbox) + label:before,
input[type='radio']:not(.radio-checkbox) + label:after {
    border-radius: 100%;
}

// Is checked
input[type='radio']:checked + label:after,
input[type='checkbox']:checked + label:after {
    opacity: 1;
}

input[type='radio'] + label.error:before,
input[type='checkbox'] + label.error:before {
    background-color: $back-color--input-error;
}

input[type='radio'].has-error + label,
input[type='checkbox'].has-error  + label {
    color: $text-color--error-1;

    .text-link {
        color: $text-color--error-1;
        border-color: $text-color--error-1;
    }

    &:before {
        background-color: $back-color--input-error;
    }

}

// Form Response
// -------------
.form-response {
    opacity: 1;
    max-height: 16rem;
    margin-bottom: $margin--s;

    &[aria-expanded] {
        overflow: hidden;
        transition: max-height $trans-time--m $trans-func--default,
                    opacity $trans-time--m $trans-func--default,
                    margin $trans-time--m $trans-func--default,
                    color $trans-time--m $trans-func--default;
    }

    &[aria-expanded='false'] {
        max-height: 0;
        opacity: 0;
        margin-bottom: 0;
        overflow: hidden;
    }

    &.is-error {
        color: $text-color--error-1;
    }

    p {
        @include typo-zurich;
        @include typo-zurich--1;
        margin-bottom: 0.25rem;
        text-transform: none;
    }
}

// Input Response
// -------------
.input-response {
    opacity: 1;
    max-height: 8rem;
    margin-top: $margin--xxs;
    margin-bottom: $margin--xs;

    &[aria-expanded] {
        overflow: hidden;
        transition: max-height $trans-time--m $trans-func--default,
                    opacity $trans-time--m $trans-func--default,
                    margin $trans-time--m $trans-func--default,
                    color $trans-time--m $trans-func--default;
    }

    &[aria-expanded='false'] {
        max-height: 0;
        opacity: 0;
        top: 0;
        margin-bottom: 0;
        overflow: hidden;
    }

    &.is-error {
        color: $text-color--error-1;
    }

    p {
        @include typo-zurich;
        @include typo-zurich--1;
        margin-bottom: 0.25rem;
        text-transform: none;
    }
}

// Error Listing
// -------------
.form-error-listing,
.form-success-listing {
    text-align: left;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0 0 4rem 0;

        @include breakpoint(mobile) {
            margin-bottom: 2rem;
        }
    }

    &.form-error-listing-small {
        ul {
            margin-bottom: 2rem;
        }
    }

    li {
        @include typo-zurich;
        @include typo-zurich--0;
        display: block;
        margin-bottom: 0.25rem;
        text-transform: none;
    }

    li.indent {
        @include typo-zurich--0;
        padding: 0 1.5rem;
        display: block;
        text-transform: none;
    }

    li.size-default {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
    }

    li.indent:before {
        @include typo-zurich--0;
        content: '\2f';
        text-indent: -1.5rem;
        display: inline-block;
    }
}

.form-error-listing {
    li,
    li:before,
    li.indent,
    li.indent:before{
        color: $text-color--error-1;
    }
}

.form-success-listing {
    li {
        margin-bottom: 0;
    }
}
