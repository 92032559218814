.panel {
    display: flex;
    flex-direction: column;

    .panel-header {
        border-bottom: solid 1px $border-color--default;
        padding-bottom: $padding--xxxs;
        margin-bottom: $margin--xxs;
        text-align: left;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            @include typo-zurich;
            @include typo-zurich--1;
            margin: 0;
        }

        p {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            margin: 0;
        }

        .panel-header__service-balance {
            color: $text-color--account-balance;

            .panel-header__service-balance-desktop {
                @include breakpoint(one) {
                    display: none;
                }
            }

            .panel-header__service-balance-mobile {
                @include breakpoint(twofive) {
                    display: none;
                }
            }
        }
    }

    .panel-body {
        text-align: left;

        p {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            margin: 0;
        }
    }

    .panel-action {
        margin-top: $margin--s;
    }

    .panel-change {
        position: absolute;
        top: 0.5rem;
        right: 0;
        line-height: 1.1;
    }

    .panel-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        margin: -16px 0 0 -16px;
        opacity: 1;
        pointer-events: none;
        background: transparent url('../images/loader/myk-loader-default.gif') 50% 50% no-repeat;
        background-size: 32px 32px;
        transition: opacity $trans-time--m $trans-func--default;
    }

    // Grey version of the panel
    &.panel--grey {
        margin-bottom: 0;
        background-color: $back-color--light-1;

        .panel-header {
            padding: $padding--xs;
            margin-bottom: 0;
            border: 0;
        }

        .panel-body {
            padding: 1rem;
        }

        .panel-change {
            top: 1.2rem;
            right: 1rem;
        }

        .panel-loader {
            background: $back-color--light-1 url('../images/loader/myk-loader-default.gif') 50% 50% no-repeat;
            background-size: 32px 32px;
        }
    }

    &.is-loaded {
        .panel-loader {
            opacity: 0;
        }
    }

    // Busy version with ajax content
    &[aria-busy] {
        .panel-body {
            transition: opacity $trans-time--overlay $trans-func--overlay;
            opacity: 1;
        }
    }

    &[aria-busy="true"],
    &.is-loaded[aria-busy="true"] {
        .panel-body {
            opacity: 0;
        }

        .panel-loader {
            opacity: 1;
        }
    }
}

.panel-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .panel {
        width: calc(#{$module-width} * 2.5 - #{$padding--large} * 2);
        margin-bottom: $margin--m;
    }

    .panel-body {
        flex: 1 1 auto;
    }

    @include breakpoint(four) {
        .panel {
            width: calc(#{$module-width} * 2 - #{$padding--large} * 2);
        }
    }

    @include breakpoint(three) {
        .panel {
            width: calc(#{$module-width} * 1.5 - #{$padding--large} * 2);
        }
    }

    @include breakpoint(two) {
        .panel {
            width: calc(#{$module-width} * 1 - #{$padding--large});
        }
    }

    @include breakpoint(one) {
        .panel {
            width: $module-width;
        }

        .team-member-body-column:first-child {
            margin-bottom: $margin--s;
        }
    }
}
