// Fontface includes
@font-face {
    font-family: 'ZurichBT-Light';
    src: url('fonts/23CA42_0_0.eot');
    src: url('fonts/23CA42_0_0.eot?#iefix') format('embedded-opentype'),
         url('fonts/23CA42_0_0.woff') format('woff'),
         url('fonts/23CA42_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'ZurichBT-Bold';
    src: url('fonts/23CA42_1_0.eot');
    src: url('fonts/23CA42_1_0.eot?#iefix') format('embedded-opentype'),
         url('fonts/23CA42_1_0.woff') format('woff'),
         url('fonts/23CA42_1_0.ttf') format('truetype');
}

@font-face {
    font-family: 'ZurichBT-Roman';
    src: url('fonts/2AF094_0_0.eot');
    src: url('fonts/2AF094_0_0.eot?#iefix') format('embedded-opentype'),
         url('fonts/2AF094_0_0.woff') format('woff'),
         url('fonts/2AF094_0_0.ttf') format('truetype');
}