.select2-selection {
    transition: background-color $trans-time--m $trans-func--default;
}

.select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: $input-size--default;
    line-height: $input-size--default;
    user-select: none;
    background-color: $back-color--input;

    .select2-selection__rendered {
        display: block;
        padding-left: 1rem;
        padding-right: 3rem;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .select2-selection__clear {
        position: relative;
    }
}

&.select2-container--clean {
    .select2-selection--single {
        background-color: $back-color--default;
        height: $input-size--small;
        line-height: $input-size--small;
    }

    .select2-selection__rendered {
        padding-left: 0;
    }
}

&.select2-container--quantity {
    .select2-selection--single {
        background-color: transparent;
        height: 1.5rem;
        line-height: 1.5;
        box-shadow: none;
        transition: background $trans-time--m $trans-func--default,
                    box-shadow $trans-time--m $trans-func--default;
    }

    .select2-selection__rendered {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &.select2-container--open {
        .select2-selection--single {
            background-color: $back-color--default;
            box-shadow: 0 0 4px 1px rgba(50, 50, 50, 0.15);
        }
    }
}
