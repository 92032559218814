.filter-site-content {
    text-align: center;
    overflow: hidden;
    width: 100%;

    // Basic alignment
    .filter-site-content-selection {
        height: 100%;
        justify-content: center;
    }

    .filter-options-item,
    .filter-selection-action {
        margin: 0.25rem 1rem;
    }

    .filter-site-content-selection,
    .filter-site-content-link {
        overflow: hidden;
        opacity: 1;
        max-height: 6rem;
        transition: max-height $trans-time--m $trans-func--default,
                    opacity $trans-time--m $trans-func--default;
    }

    // Inline container styling
    &.filter-site-content--inline {
        .filter-site-content-selection {
            max-height: 0;
            opacity: 0;
            pointer-events: none;
        }
    }

    &.filter-site-content--inline.is-filtered {
        .filter-site-content-link {
            max-height: 0;
            opacity: 0;
            pointer-events: none;
        }

        .filter-site-content-selection {
            opacity: 1;
            max-height: 6rem;
            pointer-events: all;
        }
    }

    // Sticky container is active
    &.filter-site-content--sticky {
        position: fixed;
        top: 4.5rem;
        left: 0;
        background: $back-color--footer;
        height: 5rem;
        pointer-events: none;
        z-index: 10;
        opacity: 0;
        transform: translate3d(0, 0, 0);
        transition: opacity $trans-time--m $trans-func--default,
                    transform $trans-time--overlay $trans-func--overlay;

        @include breakpoint(mobile) {
            top: 4rem;
        }
    }

    &.filter-site-content--sticky.is-visible.is-filtered {
        opacity: 1;
        pointer-events: all;
    }
}

.no-filter-results {
    display: block;
    text-align: center;
    margin-top: $margin--m;

    p {
        @include typo-zurich;
        @include typo-letter--2;
        text-transform: none;
        margin-bottom: 0;
    }
}