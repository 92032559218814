.signup-container {
    margin-top: $margin--m;
}

.sign-up-box {
    position: relative;
    max-width: 1.5 * $module-width;

    form {
        position: relative;
    }

    @include breakpoint(mobile) {
        max-width: $module-width;
    }
}

.sign-up-info {
    text-align: left;

    p {
        @include typo-letter;
        @include typo-letter--text;
        text-transform: none;
        text-align: left;
    }
}

.tooltip--signup {
    text-align: left;
    left: auto;
    right: -2rem;
    transform: translate3d(100%, 0, 0);

    &.password-focussed:after {
        top: 8.5rem;
    }

    @include breakpoint(two) {
        width: $module-width - 4rem;
        top: 1rem;

        &:after {
            margin-top: -1rem;
        }
    }
}

.signup-process-terms-of-use {
    display: block;
    margin-bottom: $margin--s;

    label {
        text-transform: none;
    }
}
