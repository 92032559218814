table {
    width: 100%;
    border-collapse: collapse;

    &.table--compact {
        width: auto;
    }

    // Typography
    th,
    td {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;

        &.is-primary,
        &.is-secondary {
            @include typo-zurich-bd;
            @include typo-zurich--1;
            text-transform: none;
        }
    }

    th,
    td {
        padding: 0.25rem;
        text-align: center;

        &.is-primary {
            padding: 0.5rem;
        }
    }

    // Define border
    th:not(.is-primary),
    td {
        border-bottom: solid 1px $border-color--light-1;
    }

    // Define background
    th.is-primary {
        background-color: $back-color--dark-1;
        color: $text-color--light;
    }

    th.is-secondary {
        background-color: $back-color--light-1;
    }

    th.is-secondary,
    th.is-tertiary {
        padding: 0.5rem;
    }

    td.is-primary {
        background-color: $back-color--light-1;
    }

    &.table--shipping-costs {
        margin-top: $margin--m;
        max-width: 3 * $module-width;

        thead {
            .is-secondary {
                width: 30%;
            }

            .is-tertiary {
                width: 40%;
            }
        }

        tbody {
            .is-primary {
                text-align: center;
            }
        }

        .shipping-costs-country-iso {
            &:before {
                content: '(';
            }

            &:after {
                content: ')';
            }
        }

        @include breakpoint(one) {
            .shipping-costs-country-name {
                display: none;
            }

            .shipping-costs-country-iso {
                &:before,
                &:after {
                    content: '';
                }
            }

            thead {
                .is-secondary {
                    width: 30%;
                }

                .is-tertiary {
                    width: 70%;
                }
            }

            tbody {
                .is-primary {
                    text-align: center;
                }
            }
        }
    }

    &.table--shipping-costs-canada {
        max-width: 32rem;
    }

    &.table--shipping-costs-apac {
        margin-bottom: $margin--s;

        .is-secondary:nth-child(0) {
            width: 40%;
        }

        .is-secondary:nth-child(1),
        .is-secondary:nth-child(2) {
            width: 30%;
        }
    }
}

.table-group {
    display: flex;
    justify-content: space-between;

    &.table-group--3 {
        table {
            width: 30%;
        }
    }

    // Remove top margin
    .table.table--shipping-costs {
        margin-top: 0;
    }

    @include breakpoint(one) {
        &.table-group--3 {
            flex-direction: column;

            table {
                width: 100%;

                &:not(:first-child) {
                    margin-top: $margin--s;
                }
            }
        }
    }
}