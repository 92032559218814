.accordion {
    display: block;

    .accordion__head {
        display: block;
        position: relative;
        margin-bottom: $margin--s;

        a[role="button"] {
            position: relative;
            display: block;
            text-decoration: none;
        }

        h3 {
            @include typo-zurich;
            @include typo-zurich--2;
            margin-bottom: 0;
        }
    }

    .accordion__head__icon {
        position: absolute;
        right: 0;
        top: 0.1rem;
        height: 1rem;
        width: 1rem;
        transform: scaleY(1);
        transition: transform $trans-time--m $trans-func--default;

        .svg-ico {
            width: 100%;
            height: 100%;
        }
    }

    .accordion__body {
        overflow: hidden;
        width: 100%;
    }

    .accordion__body__inner {
        display: block;
        width: 100%;
        padding: 0 0 $padding--s 0;
    }

    // Expanded states
    &[aria-expanded="false"] {
        .accordion__body {
            max-height: 0;
            visibility: hidden;
        }
    }

    &[aria-expanded="true"] {
        .accordion__head__icon {
            transform: scaleY(-1);
        }

        .accordion__body {
            max-height: none;
        }
    }

    // Mobile accordions
    @include breakpoint(desktop) {
        &.accordion--mobile {
            .accordion__head {
                a[role="button"] {
                    cursor: default;
                }
            }

            .accordion__head__icon {
                display: none;
            }

            &[aria-expanded="false"] {
                .accordion__body {
                    max-height: none;
                    visibility: visible;
                }
            }
        }
    }
}

// Some spacing for accordions
@include breakpoint(desktop) {
    .accordion--mobile + .accordion--mobile {
        margin-top: $margin--s;
    }
}