&.layout--2-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include breakpoint(five) {
        .layout-box--1 {
            width: 3 * $module-width;
            height: 3 * $module-height;
            padding-right: $padding--default;
        }

        .layout-box--2 {
            width: 2 * $module-width;
            height: 3 * $module-height;
            padding-left: $padding--default;
        }
    }

    @include breakpoint(four) {
        .layout-box--1 {
            width: 3 * $module-width;
            height: 3 * $module-height;
            padding-right: $padding--default;
        }

        .layout-box--2 {
            width: 1 * $module-width;
            height: 3 * $module-height;
            padding-left: $padding--default;
        }
    }

    @include breakpoint(three) {
        .layout-box--1 {
            width: 2 * $module-width;
            height: 2 * $module-height;
            padding-right: $padding--default;
        }

        .layout-box--2 {
            width: 1 * $module-width;
            height: 2 * $module-height;
            padding-left: $padding--default;
        }
    }

    @include breakpoint(two) {
        flex-direction: column;

        .layout-box--1 {
            width: 2 * $module-width;
            height: 2 * $module-height;
            padding-bottom: $padding--default;
        }

        .layout-box--2 {
            width: 2 * $module-width;
            height: 3 * $module-height;
            padding-top: $padding--default;
        }
    }

    @include breakpoint(one) {
        flex-direction: column;

        .layout-box--1 {
            width: 1 * $module-width;
            height: 1 * $module-height;
            padding-bottom: $padding--default;
        }

        .layout-box--2 {
            width: 1 * $module-width;
            height: 2 * $module-height;
            padding-top: $padding--default;
        }
    }
}
