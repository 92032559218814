.customerinfo {
    text-align: left;
    padding-bottom: $padding--m;

    p,
    th,
    td {
        @include typo-zurich;
        @include typo-zurich--2;
        text-transform: none;
        margin-bottom: 0;
    }

    h2 {
        @include typo-zurich;
        @include typo-zurich--3;
        margin: 0 0 $margin--xs 0;
    }

    h3 {
        @include typo-zurich;
        @include typo-zurich--2;
        text-transform: none;
    }

    th.uppercase {
        text-transform: uppercase;
    }
}

.customerinfo__search,
.customerinfo__login {
    border: solid 1px $border-color--default;
    padding: $padding--s 3rem;
    background: $back-color--default;

    legend {
        margin-bottom: 0;
        z-index: 1;
        position: relative;
    }
}

.customerinfo__search-form,
.customerinfo__login-form {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    .form-row:first-child {
        flex: 1 1 auto;
    }

    .form-row + .form-row {
        margin-left: $margin--s;
    }

    .btn {
        padding-left: $padding--s;
        padding-right: $padding--s;
    }
}

.customerinfo__search {
    position: sticky;
    top: 1rem;
    box-shadow: #fff 0 -2rem 2rem 4rem;
}

.customerinfo__result {
    margin-top: $margin--m;
}

.customerinfo__no-result {
    @include typo-zurich;
    @include typo-zurich--2;
    text-transform: none;
    color: $text-color--error-1;
}

.customerinfo__container {
    text-align: left;
    background: rgb(250, 250, 250);
    padding: $padding--s 3rem;

    table {
        th,
        td {
            text-align: left;
        }
    }

    .table--compact {
        th,
        td {
            padding-right: $padding--s;
        }
    }

    .table--centered-content {
        th,
        td {
            text-align: center;
        }
    }

    .table--full-width {
        width: 100%;
    }
}

.customerinfo__container + .customerinfo__container {
    margin-top: $margin--s;
}

.customerinfo__subcontainer {
    display: flex;
    margin-top: $margin--xs;
}

.customerinfo__subcontainer-cell {
    margin: 0 $margin--s;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.customerinfo__subcontainer-cell-1-3 {
    flex-basis: 33.33%;
}

.customerinfo {
    // Turnover list
    .service-account__turnover-list {
        @include pseudo-list;
    }

    .service-account__turnover-group {
        @include typo-zurich-bd;
        @include typo-zurich--1;
        text-transform: none;
        display: block;

        &:not(:first-child) {
            margin-top: $margin--s;
        }
    }

    .service-account__turnover-entry {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
        display: flex;
        margin-top: $margin--xxxs;

        .service-account__turnover-entry-description {
            flex: 1 1 auto;
            text-transform: uppercase;
        }

        .service-account__turnover-entry-amount {
            margin-left: 2rem;
            white-space: nowrap;
        }
    }
}

.customerinfo__details-summary {
    summary {
        @include typo-zurich;
        @include typo-zurich--2;
        text-transform: none;
        cursor: pointer;
        margin-bottom: $margin--xs;
    }
}

.customerinfo__container--orders {
    .customerinfo__details-summary table {
        margin-bottom: $margin--s;
    }
}