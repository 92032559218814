// Default module definition
.module {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}


.box-link {
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;

    .video-button,
    picture,
    .service-list__item-image,
    .service-list__item-icon {
        opacity: 1;
        transition: opacity $trans-time--m $trans-func--easing;
    }

    &:not(.box-link--out-of-stock):hover {
        picture,
        .service-list__item-image,
        .service-list__item-icon {
            opacity: 0.5;
        }
    }

    &.box-link--out-of-stock {
        cursor: not-allowed;
    }
}

.zoom-link {
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.module--video {
    .video-button {
        transform: translate3d(0, 0, 0);
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -3rem 0 0 -3rem;
        width: 6rem;
        height: 6rem;
        z-index: 1;
        opacity: 0;
        pointer-events: none;

        .svg-ico {
            width: 100%;
            height: 100%;
            fill: #fff;
        }

        @include breakpoint(one) {
            width: 4rem;
            height: 4rem;
            margin: -2rem 0 0 -2rem;
        }
    }

    &.has-visible-actions:not(.is-playing) {
        .video-button--play {
            opacity: 0.6;
        }
    }

    .touch & {
        .video-button--play {
            opacity: 0.6;
        }

        &.is-playing {
            .video-button--play {
                opacity: 0;
            }
        }
    }
}

.video-box {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}
