// Typefaces
// ---------

// Letter Gothic
@mixin typo-letter {
    font-family: 'letter-gothic-std', Courier, monospace;
}

// Zurich Light
@mixin typo-zurich {
    font-family: ZurichBT-Light, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    font-weight: 100;
}

// Zurich Regular
@mixin typo-zurich-reg {
    font-family: ZurichBT-Roman, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    font-weight: 100;
}

// Zurich Bold
@mixin typo-zurich-bd {
    font-family: ZurichBT-Bold, 'Arial Black', Gadget, sans-serif;
    text-transform: uppercase;
    font-weight: 100;
}

// Type styles
// -----------

@mixin typo-letter--s {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
    line-height: 1.7;
}

// Letter headline half small module
@mixin typo-letter--s2 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.05em;
    line-height: 1.7;
}

// Letter for Form-Elements
@mixin typo-letter--0 {
    font-weight: 100;
    font-size: 1.0rem;
    letter-spacing: 0.05em;
    line-height: 1;
}

@mixin typo-letter--1-1 {
    font-weight: 100;
    text-transform: uppercase;
    font-size: 1.1rem;
    letter-spacing: 0.1em;
    line-height: 1.7;
}

// Letter headlines
@mixin typo-letter--1 {
    font-weight: 100;
    text-transform: uppercase;
    font-size: 1.15rem;
    letter-spacing: 0.05em;
    line-height: 1.7;
}

// Letter in Text
@mixin typo-letter--text {
    font-weight: 100;
    text-transform: none;
    font-size: 1.25rem;
    letter-spacing: 0.04em;
    line-height: 1.5;
}

// Letter in Text
@mixin typo-letter--text-small {
    font-weight: 100;
    text-transform: none;
    font-size: 0.9rem;
    letter-spacing: 0.04em;
    line-height: 1.5;
}

// Letter running copy
@mixin typo-letter--2 {
    font-weight: 100;
    font-size: 1.3rem;
    letter-spacing: 0.08em;
    line-height: 1.75;

    @include breakpoint(one) { // smaller running text for one row mobile
        font-size: 1.05rem;
    }
}

@mixin typo-letter--3 {
    font-weight: 100;
    font-size: 1.8rem;
    letter-spacing: 0.08em;
    line-height: 1.5;

    @include breakpoint(one) { // smaller running text for one row mobile
        font-size: 1.3rem;
    }
}
// tiny Zurich for half small elements -> mobile
@mixin typo-zurich--s {
    font-size: 0.8rem;
    line-height: 1.5;
    letter-spacing: 0;
}

// tiny Zurich for half small elements -> mobile
@mixin typo-zurich--s2 {
    font-size: 0.9rem;
    line-height: 1.5;
    letter-spacing: 0;
}

// tiny Zurich // smaller button style
@mixin typo-zurich--0 {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0;
}

@mixin typo-zurich--0-lh {
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1;
}

// Small Zurich
@mixin typo-zurich--1 {
    font-size: 1.1rem;
    line-height: 1.5;
    letter-spacing: 0;
}

@mixin typo-zurich--1-link {
    font-size: 1.1rem;
    line-height: 1.1;
    letter-spacing: 0;
    text-transform: none;
}

// Small Zurich
@mixin typo-zurich--1-lh {
    font-size: 1.1rem;
    letter-spacing: 0;
    line-height: 1;
}

// Small Zurich
@mixin typo-zurich--1-1 {
    font-size: 1.2rem;
    line-height: 1.5;
    letter-spacing: 0;
    color: #000;
}

// Medium Zurich
@mixin typo-zurich--2 {
    font-size: 1.35rem;
    line-height: 1.5;
    letter-spacing: 0;
}

// Zurich Price
@mixin typo-zurich--p {
    font-size: 1.10rem;
    line-height: 1.5;
    letter-spacing: 0;
}

// Big Zurich
@mixin typo-zurich--3 {
    font-size: 1.75rem;
    line-height: 1.3;
    letter-spacing: 0;
}

// Extra Zurich
@mixin typo-zurich--4 {
    font-size: 2.4rem;
    line-height: 1.3;
    letter-spacing: 0;
}

// Extra Zurich
@mixin typo-zurich--4-s {
    font-size: 2rem;
    line-height: 1.3;
    letter-spacing: 0;
}

// Mylon Zurich
@mixin typo-zurich--5 {
    font-size: 3.5rem;
    line-height: 1.3;
    letter-spacing: 0;
}

@mixin typo-zurich--6 {
    font-size: 5rem;
    line-height: 1;
    letter-spacing: 0;
}

h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

h1 {
    @include typo-zurich-bd;
    @include typo-zurich--2;

    @include breakpoint(two) {
        @include typo-zurich--3;
    }

    @include breakpoint(threefive) {
        @include typo-zurich--4;
    }
}

h2 {
    @include typo-zurich-bd;
    @include typo-zurich--1;

    &.rem-based {
        margin-bottom: 0.5rem;
    }
}

h3, h4 {
    @include typo-letter;
    @include typo-letter--1;
}

h2.border {
    @include typo-zurich;
    @include typo-zurich--1;
    line-height: 2em;
    border-bottom: solid 1px #000;
    margin-bottom: 0.75rem;
}

h3.border {
    @include typo-zurich;
    @include typo-zurich--1;
    line-height: 2em;
    border-bottom: solid 1px $border-color--light-1;
    margin-bottom: 0.75rem;
}

p {
    margin-top: 0;

    &.is-error {
        color: $text-color--error-1;
    }
}

a {
    color: $text-color--default;
    text-decoration: none;
}

.text-link {
    @include typo-zurich;
    @include typo-zurich--1-link;
    line-height: 1.1;
    display: inline-block;
    border-bottom: solid 1px black;
    padding: 0;
    text-decoration: none;
}

.link-underlined {
    @include typo-zurich;
    @include typo-zurich--1-link;

    display: inline-block;
    border-bottom: solid 1px black;
    padding: 0;
    text-decoration: none;
    transition: color $trans-time--s $trans-func--default,
                border $trans-time--s $trans-func--default;

    &:hover {
        color: rgb(150, 150, 150);
        border-color: rgb(150, 150, 150);
    }
}

.content-link {
    font-family: inherit;
    font-size: 1em;
    display: inline;
    border-bottom: solid 1px black;
    padding: 0;
    text-decoration: none;
    transition: color $trans-time--s $trans-func--default,
                border $trans-time--s $trans-func--default;

    &:hover {
        color: rgb(150, 150, 150);
        border-color: rgb(150, 150, 150);
    }
}

.text-unavailable {
    color: $text-color--grey-light-2;
    font-style: italic;
}

th {
    @include typo-zurich;
    @include typo-zurich--1;
}

td {
    @include typo-letter;
    @include typo-letter--1;
    text-transform: none;
}

i.break {
    display: none;
}

@include breakpoint(one) {
    i.break.br1 {
        display: block;
    }
}

@include breakpoint(two) {
    i.break.br2 {
        display: block;
    }
}

@include breakpoint(three) {
    i.break.br3 {
        display: block;
    }
}

@include breakpoint(four) {
    i.break.br4 {
        display: block;
    }
}

@include breakpoint(five) {
    i.break.br5 {
        display: block;
    }
}

.legal-text-container,
.cms-container .legal-text-container  {
    $main-section-indent: 4rem;
    $main-section-indent--mobile: 2rem;
    $legal-ol-indent: 4.5rem;
    $legal-ul-indent: 2rem;

    h1 {
        @include typo-zurich-bd;
        @include typo-zurich--3;
        margin-bottom: 1em;
    }

    h2 {
        @include typo-zurich-bd;
        @include typo-zurich--2;
        margin-bottom: 1em;
        margin-top: 2em;

        em {
            font-style: inherit;
            font-size: inherit;
            display: inline-block;
            width: $main-section-indent;
        }
    }

    h3 {
        @include typo-zurich-bd;
        @include typo-zurich--1;

        em {
            font-style: inherit;
            font-size: inherit;
            display: inline-block;
            width: $main-section-indent;
        }
    }

    p {
        @include typo-letter;
        @include typo-letter--text;
    }

    ol {
        list-style-type: none;
        counter-reset: legal-section-ol;
        margin: 0 0 0 $main-section-indent;
        padding-left: $legal-ol-indent;

        // Keep nested orderd list on the same indent
        ol {
            margin-left: 0;
        }

        & > li {
            position: relative;
            text-align: left;
            padding-right: 1rem;
        }

        & > li:before {
            @include typo-letter;
            @include typo-letter--text;
            position: absolute;
            counter-increment: legal-section-ol;
            left: $legal-ol-indent * (-1);
            top: 0;
            content: counter(legal-section-ol) ".";
        }

        &.ol--lower-alpha {
            > li:before {
                content: "(" counter(legal-section-ol, lower-alpha) ")";
            }
        }

        &.ol--prefix {
            > li:before {
                content: attr(data-prefix) counter(legal-section-ol) ".";
            }
        }
    }

    ul {
        & > li {
            position: relative;
            display: block;
            padding-left: $legal-ul-indent;
            padding-right: 0;
        }

        & > li:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '\2f';
            text-align: left;
            display: inline-block;
            text-indent: 0;
        }

        & > li p:only-child {
            margin: 0;
        }
    }

    @include breakpoint(one) {
        h2 em,
        h3 em {
            width: $main-section-indent--mobile;
        }

        ol {
            margin-left: 0;
        }
    }
}