// Inline Icons / See: https://github.com/TrySound/postcss-inline-svg
//  @svg-load icon--fir-drowdown-big url(../icons/fir-drowdown-big.svg) {
//      stroke: $icon-color--form;
//  }

.svg-ico {
    display: inline-block;
    line-height: 1;

    & > svg {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.svg-ico-size--inline {
    width: 1em;
    height: 1em;
    vertical-align: text-top;
}

.svg-ico-size--inline-xl {
    width: 0.9em;
    height: 0.9em;
    vertical-align: baseline;
    margin-bottom: -0.1em;
}

.svg-ico-product-service-credit {
    fill: $icon-color--service-credit;
}