.modal--account {
    .account-option-list {
        @include pseudo-list;
    }

    .account-option {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: $margin--xxxs;
        }

        a {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            flex: 1 1 auto;
        }

        .account-option__balance {
            display: inline-block;
            margin-left: auto;

            p {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
                color: $text-color--account-balance;
                margin-bottom: 0;
            }
        }
    }
}