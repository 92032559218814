.dispatch-listing,
.panel-summary-dispatch {
    .dispatch-entry {
        display: block;
        position: relative;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    p,
    .panel-content p {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
        padding-bottom: 0.5rem;
    }

    .name {
        @include clearfix;
        padding-bottom: 0.5rem;
    }

    .description {
        @include clearfix;
        padding-left: 2rem;
    }

    .dispatch-entry-costs {
        @include typo-zurich-bd;
    }
}
