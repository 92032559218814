.mykita-container--service {
    margin-top: $margin--m;
    margin-bottom: $margin--l;
    text-align: left;

    h2 {
        @include typo-zurich-bd;
        @include typo-zurich--3;
    }

    .breadcrumb + h2 {
        margin-top: $margin--m;
    }
}

.service-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: $margin--xs $padding--large * (-1) 0 $padding--large * (-1);
}

.service-list__item {
    width: $module-width;
    padding: $padding--large;
    margin-top: $margin--xs;
}

.service-list__item-icon {
    display: block;
    width: 100%;

    svg {
        width: 100%;
    }
}

.service-list__item-label {
    margin-top: $margin--xxs;
}

.service-list__item-subline {
    @include typo-zurich;
    @include typo-zurich--1;
    text-transform: none;
    margin: $margin--xxxs 0 0 0;
}