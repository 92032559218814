.expandable {
    .expandable__sentence--visible {
        display: inline;
    }

    .expandable__content,
    .expandable__action {
        display: block;
    }

    // Hide not visible sentences
    &[aria-expanded="false"] {
        .expandable__sentence:not(.expandable__sentence--visible) {
            display: none;
        }
    }

    &[aria-expanded="true"] {
        .expandable__action {
            display: none;
        }
    }

    // Show content on desktop for mobile expandables
    @include breakpoint(desktop) {
        &.expandable--mobile {
            &[aria-expanded="false"] {
                .expandable__sentence {
                    display: inline;
                }
            }

            .expandable__action {
                display: none;
            }
        }
    }
}