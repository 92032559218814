// See general styling in components/_checkout-cart.scss

.modal.modal--wishlist {
    @include breakpoint(two) {
        .product-details {
            flex-wrap: wrap;
        }

        .product-details-image {
            width: 100%;

            .image-wrapper {
                width: 100%;
                max-height: none;
            }
        }

        .product-details-description {
            min-width: auto;
            width: 16rem;
        }
    }

    .product-add-to-basket {
        text-align: right;

        @include breakpoint(desktop) {
            width: 12rem;
            padding-top: 0.5rem;

            &.product-add-to-basket--discount {
                padding-top: 2rem;
            }
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }

    .modal-action {
        display: flex;

        @include breakpoint(desktop) {
            .btn {
                &:not(:first-child) {
                    margin-left: $margin--xs;
                }
            }
        }

        @include breakpoint(mobile) {
            flex-direction: column;

            .btn {
                &:not(:first-child) {
                    margin-top: $margin--xxs;
                }
            }
        }
    }
}
