.media-box {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    height: 100%;

    picture {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
        }

        &[data-hfocus],
        &[data-vfocus] {
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: auto;
                height: auto;
            }
        }
    }

    // Media box sizings
    &.media-box--small {
        width: $module-width;
        height: $module-height;
    }

    &.media-box--small-quad {
        width: $module-width;
        height: $module-width;
    }

    &.media-box--bigger {
        width: $module-width;
        height: $module-height;

        @include breakpoint(two) {
            width: 2 * $module-width;
            height: 2 * $module-height;
        }

        @include breakpoint(desktop) {
            width: 3 * $module-width;
            height: 3 * $module-height;
        }
    }

    &.media-box--quad {
        width: 1 * $module-width;
        height: 2 * $module-height;

        @include breakpoint(two) {
            width: 2 * $module-width;
            height: 3 * $module-height;
        }

        @include breakpoint(desktop) {
            width: 2 * $module-width;
            height: 3 * $module-height;
        }
    }

    &.media-box--extra {
        width: $module-width;
        height: $module-height;

        @include breakpoint(two) {
            width: 2 * $module-width;
            height: 2 * $module-height;
        }

        @include breakpoint(three) {
            width: 3 * $module-width;
            height: 2.5 * $module-height;
        }

        @include breakpoint(four) {
            width: 4 * $module-width;
            height: 3 * $module-height;
        }

        @include breakpoint(five) {
            width: 5 * $module-width;
            height: 3.5 * $module-height;
        }
    }

    &.media-box--info {
        @include aspect-ratio(3, 2);
    }

    &.media-box--info-tower {
        @include aspect-ratio(2, 3);
    }
}

// Lazy loading
.lazy {
    opacity: 0;
    transition: opacity $trans-time--l $trans-func--easing;

    &.lazy--loaded {
        opacity: 1;
    }
}
