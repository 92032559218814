.select2-dropdown {
    background-color: $back-color--input;
    border: 0;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: map-get($z-index, select-2);
}

.select2-results {
    display: block;
    padding: 0;
    max-height: 20rem;
    overflow-y: auto;
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
}

.select2-results__option {
    padding: 0 1rem;
    user-select: none;
    transition: background $trans-time--m $trans-func--default;

    &[aria-selected] {
        cursor: pointer;
    }

    // Nested option
    .select2-results__option {
        @include typo-letter;
        @include typo-letter--0;
        text-transform: none;
        height: 2.5rem;
        line-height: 2.5rem;
        margin: 0 -1rem;

        &.select2-results__option--highlighted,
        &[aria-selected="true"] {
            background-color: $back-color--input-light-2;
        }
    }
}

.select2-container--open .select2-dropdown {
    left: 0;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-search--dropdown {
    display: block;
    padding: 1rem;

    .select2-search__field {
        background-color: $back-color--input-light-1;

        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }
    }

    &.select2-search--hide {
        display: none;
    }
}

.select2-results__group {
    @include typo-zurich;
    @include typo-zurich--0;
    text-transform: uppercase;
    line-height: 2.5rem;
    margin-top: 1rem;
}

.select2-container--clean {
    .select2-dropdown {
        background-color: $back-color--default;
        box-shadow: 0 0 5px 1px rgba(50, 50, 50, 0.15);
        margin-top: 0.5rem;
    }

    .select2-results {
        overflow: hidden;
    }

    .select2-results__option {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
        height: 3rem;
        line-height: 3rem;
        //margin: 0 -1rem;

        &[aria-selected="true"] {
            background-color: $back-color--default;
        }

        &.select2-results__option--highlighted {
            background-color: $back-color--input-light-1;
        }
    }
}

.select2-container--quantity {
    .select2-dropdown {
        background-color: $back-color--default;
        box-shadow: 0 4px 4px 1px rgba(50, 50, 50, 0.15);
    }

    .select2-results {
        overflow-x: hidden;
    }

    .select2-results__option {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
        height: 2.5rem;
        line-height: 2.5rem;
        margin: 0;
        padding-left: 2rem;

        &[aria-selected="true"] {
            background-color: $back-color--default;
        }

        &.select2-results__option--highlighted {
            background-color: $back-color--input-light-3;
        }
    }
}

.select2-container--default {
    .select2-dropdown {
        background-color: $back-color--input;
    }

    .select2-results__option {
        @include typo-letter;
        @include typo-letter--1;
        text-transform: none;
        margin: 0;
        padding-left: 1rem;
        padding-right: 1rem;

        &[aria-selected="true"] {
            background-color: $back-color--input-light-1;
        }

        &.select2-results__option--highlighted {
            background-color: $back-color--input-light-1;
        }

        &:not([role="group"]) {
            height: 2.5rem;
            line-height: 2.5rem;
        }

        // Nested
        .select2-results__option {
            padding-left: 0;
            padding-right: 0;
        }
    }
}