.development-grid {
    display: none;

    &[aria-hidden="false"] {
        display: block;
        position: fixed;
        z-index: map-get($z-index, development-grid);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        list-style: none;
        margin: 0;
        padding: 0;
        pointer-events: none;

        li {
            display: block;
            border-bottom: solid 1px magenta;
            height: 5%;
        }
    }
}