&.layout--3 {
    width: 100%;
    padding-bottom: 40.3515%;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}