// See general styling in components/_checkout-cart.scss

.modal.modal--cart {
    .modal-content__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin--s;

        p {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
            margin-bottom: 0;

            b,
            strong {
                @include typo-zurich-bd;
                text-transform: none;
            }
        }

        .modal-content__info-service-balance {
            color: $text-color--account-balance;
        }
    }

    @include breakpoint(two) {
        .product-details {
            flex-wrap: wrap;
        }

        .product-details-image {
            width: 100%;

            .image-wrapper {
                width: 100%;
                max-height: none;
            }
        }

        .product-details-description {
            min-width: auto;
            width: 16rem;
        }
    }
}
