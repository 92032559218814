.claim-registration {
    text-align: left;
    margin-top: $margin--m;
    margin-bottom: $margin--l;

    &[aria-hidden="true"] {
        display: none;
    }

    .subline {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
    }
}

.claim-registration__intro {
    margin-bottom: $margin--s;
}

.claim-registration__form {
    margin-top: $margin--m;

    legend {
        @include typo-zurich-bd;
        @include typo-zurich--1;
        margin-bottom: $margin--xs;

        &.has-subline {
            span {
                display: block;
            }

            small {
                @include typo-zurich;
                @include typo-zurich--0;
                display: block;
                text-transform: none;
            }
        }
    }

    fieldset + fieldset {
        margin-top: $margin--m;
    }
}

// General claim fieldset
.claim-registration__fieldset {
    &[aria-hidden="true"] {
        display: none;
    }

    &.claim-registration__fieldset--submit {
        padding-top: $padding--m;
        margin-top: auto;
    }
}

// Product details
.claim-registration__fieldset--product-details {
    select,
    .select2 {
        width: 10rem !important;
    }

    .option-group--acetate-hinges {
        label {
            span,
            small {
                display: block;
            }

            small {
                @include typo-zurich;
                @include typo-zurich--s2;
                text-transform: none;
            }
        }
    }

    .option-group--frame-part {
        &:lang(de) {
            flex-wrap: nowrap;
            flex-direction: column;

            .option-group-item,
            .option-group__item {
                width: 100%;

                &:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: $margin--xxs;
                }
            }
        }
    }

    @include breakpoint(onethree) {
        .option-group--2-column,
        .option-group--acetate-hinges {
            flex-wrap: nowrap;
            flex-direction: column;

            .option-group-item,
            .option-group__item {
                width: 100%;

                &:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: $margin--xxs;
                }
            }
        }
    }
}

// Replacement order
.claim-registration__fieldset--replacement-order {
    .input-group--claim-replacement {
        .input-group-item:first-child {
            flex: 1 1 auto;
        }

        .input-group-item:last-child {
            display: flex;
            justify-content: flex-end;
            width: 14rem;

            .option-group__item {
                margin-right: 0;
            }
        }
    }
}

// Reason
.claim-registration__fieldset--reason {
    textarea {
        width: 100%;
        height: 12rem;
        resize: vertical;
    }
}

// Upload
.claim-registration__fieldset--upload {
    label {
        cursor: pointer;
    }

    input[type="file"] {
        @include hide();
    }

    .btn {
        transition: opacity $trans-time--m $trans-func--default;
        opacity: 0;
    }

    .upload-file-list {
        transition: margin $trans-time--m $trans-func--default;

        &:not(:empty) {
            margin: $margin--xxs 0 $margin--s 0;
        }
    }

    .upload-file-list__progress {
        transition: height $trans-time--s $trans-func--default;
    }

    .upload-file-list__item[data-finish="true"] {
        .btn {
            opacity: 1;
        }

        .upload-file-list__progress {
            height: 0;
        }
    }

    .upload-file-list__item:not([data-finish="true"]) {
        .svg-ico-file {
            animation: wait-pulse-inverted 1s infinite ease-in-out;
        }
    }

    .upload-file-list__name {
        @include truncate(52rem);
    }

    @include breakpoint(four) {
        .upload-file-list__name {
            @include truncate(40rem);
        }
    }

    @include breakpoint(three) {
        .upload-file-list__name {
            @include truncate(28rem);
        }
    }

    @include breakpoint(mobile) {
        .upload-file-list__name {
            @include truncate(18rem);
        }
    }
}

// Confirmation
.claim-registration--confirmation {
    max-width: 72rem;
    margin-right: auto;

    h2 {
        @include typo-zurich-bd;
        @include typo-zurich--3;
        margin-bottom: 1rem;

    }

    p,
    li {
        @include typo-letter;
        @include typo-letter--text;
        text-transform: none;
        margin: 0;
    }

    ul {
        list-style: disc;
        padding-left: 1.5rem;
    }

    p + p,
    li + li,
    ul + p {
        margin-top: $margin--xs;
    }


}