.footer {
    padding: $padding--m 0;
    background-color: $back-color--footer;

    &.footer--main {
        transform: translate3d(0, 0, 0);
        transition: margin $trans-time--modal $trans-func--modal,
                    opacity $trans-time--modal $trans-func--modal,
                    transform $trans-time--overlay $trans-func--overlay;
    }

    .footer-item-list {
        @include pseudo-list;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .footer-item {
        display: inline-block;
        width: $module-width;
        text-align: left;

        h3 {
            margin-bottom: 1rem;
        }

        &.footer-item--copyright {
            display: flex;
            width: auto;
            flex: 1 1 auto;
            justify-content: flex-end;

            p {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
                margin: 0;
            }
        }
    }

    .footer-link-list {
        @include pseudo-list;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .footer-link {
        display: block;

        &:not(:last-child) {
            margin-bottom: $margin--xxxs;
        }

        a {
            @include typo-zurich;
            @include typo-zurich--1;
            text-transform: none;
        }
    }

    @include breakpoint(mobile) {
        .footer-item {
            &.foter-item--customer-service,
            &.footer-item--my-account {
                margin-bottom: $margin--s;
            }

            &.footer-item--copyright {
                justify-content: flex-start;
                align-items: flex-end;
            }
        }
    }

    @include breakpoint(one) {
        .footer-item {
            &.footer-item--the-company {
                margin-bottom: $margin--s;
            }
        }
    }
}
