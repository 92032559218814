.layout {
    position: relative;
    margin-bottom: $margin--m;

    .layout-box {
        display: inline-block;
        position: relative;
    }

    .media-box {
        width: 100%;
        height: 100%;
    }

    // Include specific layouts
    @import 'layout/2-1';
    @import 'layout/2-2';
    @import 'layout/3';
}
