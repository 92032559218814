.information-list {
    @include pseudo-list;
    display: block;
    width: 100%;
    text-align: left;

    .information-list-item {
        position: relative;
        display: block;

        &:not(:last-child) {
            margin-bottom: $margin--s;
        }

        &.has-icon {
            padding-left: $padding--m;
        }

        &.has-dot {
            padding-left: 1.5rem;

            &:before {
                content: '•';
                position: absolute;
                top: 0.15rem;
                left: 0;
                font-size: inherit;
                line-height: inherit;
            }
        }

        @include breakpoint(one) {
            &.has-icon {
                padding-left: 2.5rem;
            }
        }
    }

    .information-symbol {
        position: absolute;
        top: 0.2rem;
        left: 0;

        .svg-ico {
            display: inline-block;
            width: 16px;
            height: 16px;
            overflow: hidden;
            line-height: 1;
        }

        &.information-symbol--retail {
            .svg-ico {
                fill: $icon-color--retail-stock;
            }
        }

        &.information-symbol--consumer {
            .svg-ico {
                fill: $icon-color--consumer-stock;
            }
        }

        &.information-symbol--promotion {
            .svg-ico {
                fill: $icon-color--promotion;
            }
        }

        &.information-symbol--service-credit {
            .svg-ico {
                fill: $icon-color--service-credit;
            }
        }
    }

    h4 {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: uppercase;
    }

    &.information-list--compact {
        .information-list-item:not(:last-child) {
            margin-bottom: $margin--xxs;
        }
    }

    &.information-list--space-s {
        margin: $margin--s 0;
    }

    &.information-list--space-xs {
        margin: $margin--xs 0;
    }
}

.has-info-icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    span {
        display: inline-block;
    }
}

.info-icon-link {
    display: inline-block;
    line-height: 1.5rem;
    margin-left: 0.75rem;
    margin-top: 1px; // Align visually to middle

    .svg-ico {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        overflow: hidden;
        line-height: 1;
    }

    &.info-icon-link--s {
        line-height: 1.1rem;

        .svg-ico {
            width: 1.1rem;
            height: 1.1rem;
        }
    }
}

.information-onboarding,
.information-login,
.information-restriction {
    text-align: center;

    h1 {
        margin-bottom: $margin--m;
    }

    h2 {
        margin-bottom: $margin--s;
    }

    .info-layer-content-inner {
        padding: 2rem 4rem 0 4rem;
    }

    @include breakpoint(one) {
        .info-layer-content-inner {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.information-static-size {
    height: 2 * $module-width;
    max-height: 80vh;

    .info-layer-header {
        padding: 0 3rem 1rem 3rem;
        @include fade-cover('bottom', 3rem);
        position: relative;

        &:after {
            right: 2rem;
        }

        h1 {
            @include typo-zurich-bd;
            @include typo-zurich--2;
            margin: 0;

            @include breakpoint(two) {
                @include typo-zurich--3;
            }

            @include breakpoint(threefive) {
                @include typo-zurich--4;
            }
        }

        @include breakpoint(one) {
            @include fade-cover('bottom', 2rem);
            padding-left: 2rem;
            padding-right: 2rem;

            &:after {
                right: 2rem;
            }
        }
    }

    .info-layer-content {
        display: flex;
        flex-direction: column;

        @include breakpoint(one) {
            padding-top: 1rem;
        }
    }

    .info-layer-content-inner {
        flex: 1 1 auto;
        text-align: left;
        padding: 0;

        h3 {
            @include typo-zurich;
            @include typo-letter--1;
        }

        p + p {
            margin-top: $margin--xs;
        }
    }

    .info-layer-action {
        padding-bottom: 0;

        @include breakpoint(one) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .info-layer-pagination {
        @include fade-cover('top', 3rem);
        position: relative;
        padding-bottom: 1rem;

        &:after {
            right: 2rem;
        }
    }

    .info-layer-dont-show-again {
        padding: 2rem 0;
        justify-content: flex-start;
    }
}

.information-login,
.information-restriction {
    .info-layer-content-inner {
        padding-bottom: $padding--s;
    }
}

.information-login {
    p {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;

        &:not(:last-child) {
            margin-bottom: $margin--xs;
        }
    }
}

// Acetate Hinge Info popup
.information-acetate-hinge {
    .information-acetate-hinge__teaser {
        @include aspect-ratio(1, 1);
        background: $back-color--light-1;
        margin: $margin--s 0;
    }

    .information-acetate-hinge__text {
        @include typo-zurich;
        font-size: 2.5rem;
    }
}