.product-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: $margin--xl;

    @include breakpoint(mobile) {
        margin-bottom: $margin--l;
    }
}

.product {
    position: relative;
    width: $module-width;

    .h3-wrapper,
    .h4-wrapper,
    .icon-wrapper,
    .promotion-wrapper {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
    }

    .h3-wrapper {
        bottom: 3.75rem;
    }

    .h4-wrapper {
        bottom: 2rem;
    }

    .icon-wrapper {
        bottom: 0;
    }

    .promotion-wrapper {
        bottom: 0.25rem;
    }

    h3,
    h4,
    .promotion-wrapper p {
        @include typo-zurich;
        @include typo-zurich--1;
        line-height: 1.3;
    }

    h3 {
        display: inline-block;
        position: relative;
    }

    .promotion-wrapper p {
        color: $text-color--promotion;
        margin: 0;
    }

    &.product--has-color,
    &.product--view-more,
    &.product--view-less {
        height: $module-height + 2.5rem;

        .module {
            height: $module-height;
        }
    }

    &.product--info-layer {
        box-sizing: border-box;
        height: 16rem;

        img {
            width: 100%;
            max-height: 100%;
        }

        .h3-wrapper {
            bottom: 1.25rem;
        }
    }

    &.product--service-part {
        height: $module-height + 4rem;
    }

    .product__out-of-stock {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: $back-color--out-of-stock;

        h4 {
            position: absolute;
            top: $module-height / 2 - 0.55rem;
            left: 0;
            width: 100%;
        }
    }
}

.product-listing__category {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: $module-width;

    h2 {
        @include typo-zurich-bd;
        @include typo-zurich--2;
        display: inline-block;
    }

    @include breakpoint(one) {
        height: $module-height + 2.5rem;
    }
}