.faq-container {
    margin-top: $margin--m;
    margin-bottom: $margin--l;
    text-align: left;

    .faq-category-list-container {
        max-width: 3 * $module-width;
    }

    h1 {
        margin-bottom: $margin--s;
    }

    h2 {
        @include typo-letter;
        @include typo-letter--1;
        margin-bottom: $margin--s;
    }

    h3 {
        margin-bottom: $margin--xs;
    }

    p,
    span {
        @include typo-zurich;
        @include typo-zurich--1;
        text-transform: none;
        margin-bottom: 0;
    }

    .faq-category-overview-container {
        margin-bottom: $margin--m;
    }

    .faq-category-overview-list {
        @include pseudo-list;
        display: block;

        li {
            display: block;

            .faq-category-range {
                display: inline-block;
                margin-right: $margin--s;
            }

            &:not(:last-child) {
                margin-bottom: $margin--xxs;
            }
        }
    }

    .faq-category {
        &:not(:last-child) {
            margin-bottom: $margin--m;
        }
    }

    .faq-entry-list {
        @include pseudo-list;
        display: block;
    }

    .faq-entry {
        display: block;

        ul {
            & > li {
                @include typo-zurich;
                @include typo-zurich--1;
                text-transform: none;
                position: relative;
                display: block;
                padding-left: 2rem;
                padding-right: 0;
            }

            & > li:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '\2f';
                text-align: left;
                display: inline-block;
                text-indent: 0;
            }

            li + li {
                margin-top: 1rem;
            }
        }

        p + ul {
            margin-top: 1rem;
        }

        &:not(:last-child) {
            margin-bottom: $margin--s;
        }
    }
}
