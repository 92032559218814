// Clearfix helper
.cf:before,
.cf:after,
.clearfix:before,
.clearfix:after {
    content: ' ';
    display: table;
}

.cf:after,
.clearfix:after {
    clear: both;
}

// Enable border box sizing for all elements
*,
*:after,
*:before {
    box-sizing: border-box;
}

// Remove system specific highligh color
html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Set 100% height for document
html,
body {
    margin: 0;
    width: 100%;
}

// Remove x overflow
body {
    overflow-x: hidden;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}